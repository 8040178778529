import React, { useEffect, useRef, useState } from "react";
import { stringsTranslate } from "../../../locales/CustomTranslater";
import { hasAValue } from "../../../uitls/SharedFunctions";
import {
  appWords,
  customStyles,
  internationalDialingCodes,
} from "../../../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderKnaekDataRS } from "../../../redux/reduxActions/reduxStoreActions";
import {
  validateEmail,
  validatePhoneNumber,
  validatePhoneNumberAndSendCode,
  validatePhoneNumberForSubscription,
  validateVerificationCode,
} from "../../../uitls/DataValidator";
import ArrowOrderWizardComponent from "./ArrowOrderWizardComponent";
import {
  closeAlertFunction,
  loadingAlertFunction,
  validationErrorAlertFunction,
} from "../../../uitls/AlertFunctions";
import Select from "react-select";
import {postHitTrackingCall, startOrderWebsiteCall} from "../../../api/ApiCalls";
import { Animated } from "react-animated-css";
import { useParams } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";

const Option = (props) => {
  const handleClick = () => {
    const selectedValue = props.data.label.split(" ")[0];
    props.selectProps.onChange({ value: selectedValue, label: selectedValue });
    props.handleInputChangeSelect("countryCode", selectedValue);
    props.closeMenu();
  };

  return (
    <div
      style={{
        borderBottom: "0.5px solid rgb(220,220,220)",
        padding: "10px",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <img
        src={`https://flagcdn.com/48x36/${props?.data?.flag}.png`}
        alt={props.data.label}
        style={{ width: "24px", height: "19px" }}
      />
      <span style={{ paddingLeft: "5px" }}>
        {props.data.name}({props.data.label.split(" ")[0]})
      </span>
    </div>
  );
};

export default function OrderKnaekIDFlowStep3({ currentStep, setCurrentStep }) {
  const selectRef = useRef(null);
  const params = useParams();
  const orderKnaekDataRS = useSelector((state) => state.orderKnaekDataRS);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [step3CurrentSubStep, setStep3CurrentSubStep] = useState(1);

  const [timer, setTimer] = useState(0);

  const closeMenu = () => {
    if (selectRef.current) {
      selectRef.current.blur(); // Blur the Select component to close the menu
    }
  };

  useEffect(() => {
    if(step3CurrentSubStep === 1){
      postHitTrackingCall({event: `order-journey-3`});
    }
    if(step3CurrentSubStep === 2){
      postHitTrackingCall({event: `order-journey-4`});
    }
  }, [step3CurrentSubStep]);

  useEffect(() => {
    if (step3CurrentSubStep === 2) {
      const timerInterval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [timer, step3CurrentSubStep]);

  const initStrings = {
    order_details_heading: stringsTranslate(appWords.personal_details),
    we_only_ask_for: stringsTranslate(appWords.we_only_ask_for),
    phone_number: stringsTranslate(appWords.phone_number)
      .toString()
      .toUpperCase(),
    country_code_label: stringsTranslate(appWords.country_code_label)
      .toString()
      .toUpperCase(),
    enter_your_first_name: stringsTranslate(appWords.country_code_placeholder),
    phone_number_placeholder: stringsTranslate(
      appWords.phone_number_placeholder
    ),
    enter_your_phone_number: stringsTranslate(appWords.enter_your_phone_number),
    error_phone_number_not_good: stringsTranslate(
      appWords.error_phone_number_not_good
    ),
    country_code_not_valid: stringsTranslate(appWords.country_code_not_valid),
    verification_code: stringsTranslate(appWords.verification_code),
    enter_your_verification_code: stringsTranslate(
      appWords.enter_your_verification_code
    ),
    verification_code_error: stringsTranslate(appWords.verification_code_error),
    error_phone_number_has_subscription: stringsTranslate(
      appWords.error_phone_number_has_subscription
    ),
    not_all_data_are_valid: stringsTranslate(appWords.not_all_data_are_valid),
    select_country_code: stringsTranslate(appWords.select_country_code),
    we_will_send_you_a_code_by_to_verify_your_number: stringsTranslate(
      appWords.we_will_send_you_a_code_by_to_verify_your_number
    ),
    dont_remember_difficult_passwords: stringsTranslate(
      appWords.dont_remember_difficult_passwords
    ),
    at_knaek_you_login_with_your: stringsTranslate(
      appWords.at_knaek_you_login_with_your
    ),
    sms_code_text: stringsTranslate(appWords.sms_code_text),
  };


  useEffect(() => {}, [orderKnaekDataRS, errorMessage]);

  return (
    <section
      className="section align-items-start justify-content-start mt-5  w-100"
      style={{ zIndex: "-1" }}
    >
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-12 col-sm-12 ">
            <div className={"text-left mt-5 mb-3"}>
              <h1 className="my-3">
                {initStrings.at_knaek_you_login_with_your}
              </h1>
              <p className={"mt-4 mb-3"}>
                {initStrings.dont_remember_difficult_passwords}
              </p>
            </div>

            <div className="row">
              <div className="col-12 d-flex">
                {/*Phone number and country code*/}
                {step3CurrentSubStep === 1 && (
                  <Animated
                    className="col-12 list-group list-group-flush pr-0"
                    animationIn="bounceInLeft"
                    animationOut="bounceOutRight"
                    isVisible={step3CurrentSubStep === 1}
                    animationInDuration={1000}
                    animationInDelay={100}
                  >
                    <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                      <label className={"mb-0 pb-0"}>
                        {initStrings.country_code_label}
                      </label>
                      <Select
                        ref={selectRef}
                        className="m-0 p-0"
                        classNamePrefix="react-select"
                        placeholder={initStrings.select_country_code}
                        isClearable={false}
                        isSearchable={true}
                        name="countryCode"
                        styles={customStyles} // Pass the custom styles
                        options={internationalDialingCodes.countryCodes}
                        components={{
                          Option: (props) => (
                            <Option
                              {...props}
                              handleInputChangeSelect={handleInputChangeSelect}
                              closeMenu={closeMenu}
                            />
                          ),
                        }}
                      />
                    </li>
                    {hasAValue(errorMessage) &&
                      errorMessage["countryCode"] &&
                      errorMessage["countryCode"] && (
                        <span className={"text-danger mt-2 ml-4 font-size-12"}>
                          {initStrings.country_code_not_valid}
                        </span>
                      )}

                    <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                      <label>{initStrings.phone_number}</label>
                      <input
                        name="phoneNumber"
                        type="tel"
                        value={
                          hasAValue(orderKnaekDataRS) &&
                          orderKnaekDataRS["phoneNumber"]
                            ? orderKnaekDataRS["phoneNumber"]
                            : null
                        }
                        className="form-control bg-transparent shadow-none"
                        placeholder={initStrings.phone_number_placeholder}
                        required="required"
                        onChange={handleInputChange}
                      />
                    </li>
                    {hasAValue(errorMessage) &&
                      errorMessage["phoneNumber"] &&
                      errorMessage["phoneNumber"] && (
                        <span className={"text-danger mt-2 ml-4 font-size-12"}>
                          {initStrings.error_phone_number_not_good}
                        </span>
                      )}
                    {hasAValue(errorMessage) &&
                      errorMessage["phoneNumberSubscription"] &&
                      !errorMessage["phoneNumber"] &&
                      errorMessage["phoneNumberSubscription"] && (
                        <span className={"text-danger mt-2 ml-4 font-size-12"}>
                          {initStrings.error_phone_number_has_subscription}
                        </span>
                      )}
                  </Animated>
                )}

                {/*Verification code*/}
                {step3CurrentSubStep === 2 && (
                  <Animated
                    className="col-12 list-group list-group-flush pr-0"
                    animationIn="bounceInLeft"
                    animationOut="bounceOutRight"
                    isVisible={step3CurrentSubStep === 2}
                    animationInDuration={1000}
                    animationInDelay={100}
                  >
                    <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                      <label>{initStrings.verification_code}</label>
                      <input
                        name="verificationCode"
                        type="number"
                        value={
                          hasAValue(orderKnaekDataRS) &&
                          orderKnaekDataRS["verificationCode"]
                            ? orderKnaekDataRS["verificationCode"]
                            : null
                        }
                        className="form-control bg-transparent shadow-none"
                        placeholder={initStrings.enter_your_verification_code}
                        required="required"
                        onChange={handleInputChange}
                      />
                      {/* <label>{initStrings.verification_code}</label> */}
                      <div className="mt-4 mt-md-2" style={{display:"flex", alignItems:"center",justifyContent:"space-between"}}>
                      <div
                          className={`text-primary  ${
                            timer <= 0 ? "cursor-pointer" : ""
                          }`}
                          style={{fontSize:"14px"}}
                          onClick={() => reSendVerificationCode()}
                        >
                          <span>{initStrings.sms_code_text}</span>
                          {timer > 0 && (
                            <span>
                              {" "}
                              ({Math.floor(timer / 60)}:
                              {timer % 60 < 10 ? "0" : ""}
                              {timer % 60})
                            </span>
                          )}
                        </div>{" "}
                        {hasAValue(errorMessage) &&
                          errorMessage["verificationCode"] &&
                          errorMessage["verificationCode"] && (
                            <span
                              className={"text-danger  font-size-12"}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "flex-start",
                              }}
                            >
                              {initStrings.verification_code_error}
                            </span>
                          )}

                      </div>
                    </li>
                  </Animated>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ArrowOrderWizardComponent
        handleNext={() =>
          step3CurrentSubStep === 1 ? handleNext1() : handleNext2()
        }
        handleBack={() => handleBack()}
        currentStep={currentStep}
      />
    </section>
  );

  async function handleInputChange(event) {
    const { name, value } = event.target;
    if (name.toString().includes("phoneNumber")) {
      let updatedData = {
        ...orderKnaekDataRS,
        [name]: value.replace(/^0/, ""),
      };
      dispatch(updateOrderKnaekDataRS(updatedData));
    } else {
      let updatedData = { ...orderKnaekDataRS, [name]: value };
      dispatch(updateOrderKnaekDataRS(updatedData));
    }
  }

  async function handleInputChangeSelect(name, value) {
    let updatedData = { ...orderKnaekDataRS, [name]: value };
    dispatch(updateOrderKnaekDataRS(updatedData));
  }

  async function handleNext1() {
    setErrorMessage(null);
    if (timer > 0) {
      setStep3CurrentSubStep((step3CurrentSubStep) => step3CurrentSubStep + 1);
      return;
    }

    if (!hasAValue(orderKnaekDataRS)) {
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        countryCode: true,
      }));
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        phoneNumber: true,
      }));
      validationErrorAlertFunction(initStrings.not_all_data_are_valid);
      return;
    }

    const requiredFields = ["countryCode", "phoneNumber"];
    let isError = false;

    for (const field of requiredFields) {
      if (!hasAValue(orderKnaekDataRS[field])) {
        isError = true;
        setErrorMessage((errorMessage) => ({ ...errorMessage, [field]: true }));
      }
    }

    if (!isError) {
      const isPhoneNumberValid1 = isValidPhoneNumber(
        orderKnaekDataRS["countryCode"] + orderKnaekDataRS["phoneNumber"]
      );
      if (!isPhoneNumberValid1) {
        setErrorMessage((errorMessage) => ({
          ...errorMessage,
          phoneNumber: true,
        }));
        return;
      }

      const isPhoneNumberSubscriptionFree =
        await validatePhoneNumberForSubscription(
          orderKnaekDataRS["countryCode"],
          orderKnaekDataRS["phoneNumber"]
        );

      if (!isPhoneNumberSubscriptionFree) {
        setErrorMessage((errorMessage) => ({
          ...errorMessage,
          phoneNumberSubscription: true,
        }));
        return;
      }

      const isPhoneNumberValid2 = await validatePhoneNumberAndSendCode(
        orderKnaekDataRS["countryCode"],
        orderKnaekDataRS["phoneNumber"]
      );
      setTimer(1 * 60);

      if (
        isPhoneNumberValid2 &&
        isPhoneNumberSubscriptionFree &&
        step3CurrentSubStep === 1
      ) {
        setStep3CurrentSubStep(
          (step3CurrentSubStep) => step3CurrentSubStep + 1
        );
      } else {
        if (!isPhoneNumberValid2) {
          setErrorMessage((errorMessage) => ({
            ...errorMessage,
            phoneNumber: true,
          }));
        }
        if (!isPhoneNumberSubscriptionFree) {
          setErrorMessage((errorMessage) => ({
            ...errorMessage,
            phoneNumberSubscription: true,
          }));
        }
        validationErrorAlertFunction(initStrings.not_all_data_are_valid);
      }
    } else {
      validationErrorAlertFunction(initStrings.not_all_data_are_valid);
    }
  }

  async function handleNext2() {
    setErrorMessage(null);

    if (!hasAValue(orderKnaekDataRS)) {
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        countryCode: true,
      }));
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        phoneNumber: true,
      }));
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        verificationCode: true,
      }));
      validationErrorAlertFunction(initStrings.not_all_data_are_valid);
      return;
    }

    const requiredFields = ["countryCode", "phoneNumber", "verificationCode"];
    let isError = false;

    for (const field of requiredFields) {
      if (!hasAValue(orderKnaekDataRS[field])) {
        isError = true;
        setErrorMessage((errorMessage) => ({ ...errorMessage, [field]: true }));
      }
    }

    if (!isError) {
      const isPhoneNumberValid = validatePhoneNumber(
        orderKnaekDataRS["countryCode"],
        orderKnaekDataRS["phoneNumber"]
      );
      const isCodeValid = await validateVerificationCode(
        orderKnaekDataRS["countryCode"],
        orderKnaekDataRS["phoneNumber"],
        orderKnaekDataRS["verificationCode"]
      );

      if (isPhoneNumberValid && isCodeValid && currentStep !== 4) {
        loadingAlertFunction();
        await createAnOrder();
      } else {
        if (!isPhoneNumberValid) {
          setErrorMessage((errorMessage) => ({
            ...errorMessage,
            phoneNumber: true,
          }));
        }
        if (!isCodeValid) {
          setErrorMessage((errorMessage) => ({
            ...errorMessage,
            verificationCode: true,
          }));
        }
        validationErrorAlertFunction(initStrings.not_all_data_are_valid);
      }
    } else {
      validationErrorAlertFunction(initStrings.not_all_data_are_valid);
    }
  }

  async function createAnOrder() {
    try {
      let data = {};

      data["email"] = orderKnaekDataRS.email;
      data["student_email"] =
        orderKnaekDataRS.studentEmail &&
        validateEmail(orderKnaekDataRS.studentEmail)
          ? orderKnaekDataRS.studentEmail
          : null;
      data["agree_to_terms"] = orderKnaekDataRS.agreeToTerms;
      data["want_to_get_updates"] = orderKnaekDataRS.wantToGetUpdates || false;
      data["password"] = orderKnaekDataRS.password;
      data["first_name"] = orderKnaekDataRS.firstName;
      data["last_name"] = orderKnaekDataRS.lastName;
      data["country_code"] = orderKnaekDataRS.countryCode;
      data["phone_number"] = orderKnaekDataRS.phoneNumber;
      data["origin"] = window.location.href;

      data["is_intro"] = window.location.href.includes("intro");
      if (window.location.href.includes("intro")) {
        data["intro_city"] = params.city;
        data["intro_name"] = params.name;
      }
      closeAlertFunction();
      let response = await startOrderWebsiteCall(data);
      window.location = response.checkout_url;
    } catch (e) {
      closeAlertFunction();
      console.log(e);
      validationErrorAlertFunction(initStrings.not_all_data_are_valid);
    }
  }

  function handleBack() {
    if (step3CurrentSubStep === 2) {
      setStep3CurrentSubStep((step3CurrentSubStep) => step3CurrentSubStep - 1);
      return;
    }
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
  }

  async function reSendVerificationCode() {
    if (timer <= 0) {
      try {
        const isPhoneNumberValid2 = await validatePhoneNumberAndSendCode(
          orderKnaekDataRS["countryCode"],
          orderKnaekDataRS["phoneNumber"]
        );
        if (isPhoneNumberValid2) {
          setTimer(1 * 60);
          setErrorMessage((errorMessage) => ({
            ...errorMessage,
            verificationCode: false,
          }));
        }
      } catch (err) {
        setErrorMessage((errorMessage) => ({
          ...errorMessage,
          verificationCode: true,
        }));
        setTimer(1 * 60);
      }
    }
  }
}
