import './styles/TableComponent.css';
import React from "react";
export const TableComponent = ({ headers, rows, renderRow }) => {
    return (
        <>
            {/* Desktop Table */}
            <table className="table-hoverable d-none d-md-table">
                <thead>
                <tr>
                    {headers.map((header, i) => (
                        <th key={i}>{header.label}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {rows.length > 0 ? (
                    rows.map(renderRow)
                ) : (
                    <tr>
                        <td colSpan={headers.length} className="text-center py-3">
                            No data available
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            {/* Mobile Cards */}
            <div className="mobile-card-list d-md-none">
                {rows.length > 0 ? (
                    rows.map((row, i) => (
                        <div key={i} className="mobile-card">
                            {headers.map((header, j) => {
                                const value = row[header.field];
                                return (
                                    <div key={j} className="mobile-card-row">
                                        <span className="mobile-card-header">{header.label}</span>
                                        <span className="mobile-card-value">
                                            {/* Check for special fields like status */}
                                            {header.field === 'statusLabel' ? (
                                                <span className={`p-2 badge badge-${ row['statusBadge']}`}>
                                                      {value}
                                                </span>
                                            ) : (
                                                value
                                            )}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    ))
                ) : (
                    <div className="no-data text-center py-3">No data available</div>
                )}
            </div>
        </>
    );
};