import React, {useCallback, useState} from 'react';
import FilterCityStoreDiscounts from "../../AppCustomizedComponent/FilterCityStoreDiscounts";
import ListItemsStoreDiscounts from "../../AppCustomizedComponent/ListItemsStoreDiscounts";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import {debounce, isEqual} from "lodash";
export default function StoreDiscountsPart() {
    const initStrings = {
        section1heading: stringsTranslate(appWords.local_discounts),
        section1CounterText: stringsTranslate(appWords.results),
        sectionSearchHeading: stringsTranslate(appWords.results),
        sectionFilterHeading: stringsTranslate(appWords.college_town),
        searchPlaceholder: stringsTranslate(appWords.find_a_local_discount),
        all_categories: stringsTranslate(appWords.all_categories),
        categories: stringsTranslate(appWords.categories),
    }

    const [totalDiscountsCount, setTotalDiscountsCount] = useState(0)
    const [filters, setFilters] = useState({
        category: null,
        city: null,
        searchText: '',
    });

    const handleTotalDiscountsUpdate = (total) => {
        setTotalDiscountsCount(total);
    };
    const updateFilters = useCallback(
        debounce((newFilters) => {
            setFilters((prev) => {
                const updatedFilters = { ...prev, ...newFilters };
                if (!isEqual(prev, updatedFilters)) {
                    return updatedFilters;
                }
                return prev;
            });
        }, 300),
        [setFilters] // Ensure dependencies are current
    );

    return (
        <section className="section align-items-start justify-content-start mt-5">
            <div className="container-fluid mb-5">
                <div className="row">

                    {/*Element 1*/}
                    <div className="col-lg-2 col-12 text-left border-top border-right d-flex align-items-center">
                        <p className="text-primary">{totalDiscountsCount + " " + initStrings.section1CounterText}</p>
                    </div>

                    {/*Element Search balk*/}
                    <div className="col-lg-10 col-12 text-left border-top d-flex align-items-center">
                        <span className="input-group-text w-100 h-100 border-0 bg-white">
                            <i className="fa fa-search text-primary"/>
                            <input type="text" className="form-control search-input-store-discounts h6 h-100 mt-2"
                                   placeholder={initStrings.searchPlaceholder}
                                   onChange={(val) =>
                                       updateFilters({
                                           searchText: val.target.value
                                       })
                                   }/>
                        </span>
                    </div>

                    {/*Element Filter balk*/}
                    <div className="col-lg-2 col-12 text-left border-top border-right border-bottom pb-3 pl-3 pr-3">

                        <FilterCityStoreDiscounts initStrings={initStrings}
                                                  filters={filters}
                                                  updateFilters ={updateFilters }
                        />
                    </div>

                    {/*Element List items*/}
                    <ListItemsStoreDiscounts
                        filters={filters}
                        onTotalDiscountsUpdate={handleTotalDiscountsUpdate}
                    />
                </div>
            </div>
        </section>
    );
}
