import React, {useEffect} from 'react';
import OrderComponent from "../../components/AppSections/OrderSections/OrderComponent";
import {Helmet} from "react-helmet";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {calculateTheYearsFrom2009, calculateTheYearsFrom2010} from "../../uitls/SharedFunctions";

export default function OrderKnaek() {
    useEffect(()=>{
    },[])

    const initStrings = {
        knaek_exists: stringsTranslate(appWords.knaek_exists),
        year: stringsTranslate(appWords.year),
        now_even_better_discounts: stringsTranslate(appWords.now_even_better_discounts),
        knaek_order_title: stringsTranslate(appWords.knaek_order_title),
    }

    return (
        <div className="homepage-4">
            <div className="main">
                <Helmet>
                    <title>Knaek - {initStrings.knaek_order_title}</title>
                    <meta property="description" content={"Knaek bestaat 14 jaar. Word lid van Knaek en profiteer je studententijd lang van de beste studentenkortingen in Nederland, Vlaanderen en online."} />
                    <meta property="keywords" content={"bestellen, lidmaatschap"} />

                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content="Knaek-bestellen" />
                    <meta property="og:description" content={"Knaek bestaat 14 jaar. Word lid van Knaek en profiteer je studententijd lang van de beste studentenkortingen in Nederland, Vlaanderen en online."}/>
                    <meta property="og:keywords" content={"bestellen, lidmaatschap"}/>
                    <meta property="og:type" content="website" />

                </Helmet>
                <div className="container-fluid">
                    <div className="row">
                        <div className={"col-md-12 col-lg-5 col-sm-12"}>
                            <OrderComponent backgroundColor={"bg-white"} withStudentEmail={false} />
                        </div>
                        <div className="col-lg-7 d-none d-lg-block order-image-style ptb_100">
                            <div className="mt-5 pl-5 ptb_50">
                                <p className={"col-12 mt-5 pt-5 d-flex text-white font-size-45"}>{initStrings.knaek_exists} <p
                                    className={"text-primary pl-2 font-size-45"}>  {calculateTheYearsFrom2010()} {initStrings.year}.</p></p>
                                <p className={"col-12 text-white font-size-20"}>{initStrings.now_even_better_discounts}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}
