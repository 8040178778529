import React from 'react';
import {
    updateAuthDataNEWRS,
    updateEmailInModal,
    updateShowAuthModalRS,
    updateShowLoginLinkModal,
} from "../../redux/reduxActions/reduxStoreActions";
import {useDispatch, useSelector} from "react-redux";
import {hasAValue} from "../../uitls/SharedFunctions";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appLanguage, appWords} from "../../config/Constants";
import {afterLogoutAlertFunction} from "../../uitls/AlertFunctions";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';


export default function HeaderBE({switchLanguage}) {
    const s = useSelector(state => state.emailInModalRS);
    const handleLinkClick2 = useHandleLinkClick();

    const handleLinkClick = (link) => {
        handleLinkClick2(link)
        document.querySelector('.navbar').classList.remove('active')
        document.querySelector('body').classList.remove('canvas-open')
        document.querySelector('.navbar-toggler-icon').classList.remove('active')
    };

    const initStrings = {
        element1: stringsTranslate(appWords.store_discounts_cl),
        element2: stringsTranslate(appWords.online_discounts_cl),
        element3: stringsTranslate(appWords.student_jobs_cl),
        element4: stringsTranslate(appWords.knaek_order),
        element5: stringsTranslate(appWords.download_app),
        element6: stringsTranslate(appWords.more),
        subElements6: {
            item0: stringsTranslate(appWords.login_cl),
            item1: stringsTranslate(appWords.for_companies),
            item2: stringsTranslate(appWords.who_are_we),
            item3: stringsTranslate(appWords.our_partner),
            item4: "Knaek.box",
            item5: stringsTranslate(appWords.contact_faq),
            item6: stringsTranslate(appWords.logout),
            item7: stringsTranslate(appWords.profile),
            // item8: "Download app",
        },
    }
    const dispatch = useDispatch()
    const authDataRS = useSelector(state => state.authDataNEWRS);

    return (
        <header className="navbar navbar-sticky navbar-expand-lg bg-gradient navbar-dark navbar-custom-style">
            <div className="container-fluid position-relative">
                <a aria-label="navbar-brand cursor-pointer"   onClick={() => handleLinkClick("/")} className="logo">
                    <svg width="96" viewBox="0 0 32 12.77" className="svg-white cursor-pointer">
                        <polygon
                            points="4.79 4.83 3.36 4.83 1.24 6.96 1.24 0 0.15 0 0.15 10.47 1.24 10.47 1.24 8.28 1.61 7.91 3.63 10.47 5.03 10.47 2.38 7.17 4.79 4.83"></polygon>
                        <path
                            d="M16.18,5.77A2.77,2.77,0,0,0,15.33,5a2.15,2.15,0,0,0-1.13-.3,2.52,2.52,0,0,0-1.11.3,3,3,0,0,0-.84.66,2.9,2.9,0,0,0-.53.94,3.3,3.3,0,0,0-.19,1.12,3.85,3.85,0,0,0,.18,1.16,3,3,0,0,0,.53,1,2.53,2.53,0,0,0,.85.67,2.39,2.39,0,0,0,1.15.25,2.08,2.08,0,0,0,1.11-.3,2.62,2.62,0,0,0,.81-.78v.88h1.09V4.83H16.16Zm-.06,2.67a2.12,2.12,0,0,1-.36.66,1.65,1.65,0,0,1-.57.44,1.8,1.8,0,0,1-.79.16,1.57,1.57,0,0,1-.76-.17,1.42,1.42,0,0,1-.55-.45,2.32,2.32,0,0,1-.35-.66,2.53,2.53,0,0,1-.12-.77,2.16,2.16,0,0,1,.12-.71,2.32,2.32,0,0,1,.35-.66,1.61,1.61,0,0,1,.56-.44,1.5,1.5,0,0,1,.75-.17,1.8,1.8,0,0,1,.79.16,1.54,1.54,0,0,1,.57.44,1.81,1.81,0,0,1,.36.65,2.53,2.53,0,0,1,.12.77A2.4,2.4,0,0,1,16.12,8.44Z"></path>
                        <path
                            d="M10.06,5.37a1.59,1.59,0,0,0-.62-.53,2.39,2.39,0,0,0-1-.18,2.28,2.28,0,0,0-1,.23,1.71,1.71,0,0,0-.71.7V4.83H5.66v5.64H6.74V7.14a2,2,0,0,1,.14-.72,1.13,1.13,0,0,1,.41-.53,1.33,1.33,0,0,1,.82-.21,1.64,1.64,0,0,1,.66.11.7.7,0,0,1,.37.33,1.24,1.24,0,0,1,.18.52c0,.22,0,.43,0,.65v3.23h1.09V7.11a4.15,4.15,0,0,0-.09-1A2.23,2.23,0,0,0,10.06,5.37Z"></path>
                        <path
                            d="M23,5.54a2.26,2.26,0,0,0-.82-.65A2.72,2.72,0,0,0,21,4.66a3.16,3.16,0,0,0-1.21.22,2.45,2.45,0,0,0-.85.65,3,3,0,0,0-.52,1,4.11,4.11,0,0,0-.17,1.21,3.49,3.49,0,0,0,.19,1.19,2.76,2.76,0,0,0,.55,1,2.66,2.66,0,0,0,.86.63,2.94,2.94,0,0,0,1.17.23,3,3,0,0,0,1.53-.41,2.72,2.72,0,0,0,1.09-1.14l-.91-.51a3.15,3.15,0,0,1-.7.8,1.5,1.5,0,0,1-1,.29,1.69,1.69,0,0,1-.7-.15,2,2,0,0,1-.54-.42,2,2,0,0,1-.33-.6,1.84,1.84,0,0,1-.13-.69h4.31V7.67a4.25,4.25,0,0,0-.16-1.18A2.63,2.63,0,0,0,23,5.54ZM19.39,7a1.76,1.76,0,0,1,.53-1A1.6,1.6,0,0,1,21,5.63a1.46,1.46,0,0,1,1.05.31,1.82,1.82,0,0,1,.51,1Z"></path>
                        <path
                            d="M31.64,9.48a.67.67,0,0,0-.49-.21.71.71,0,0,0-.5.21.73.73,0,0,0-.19.49.65.65,0,0,0,.19.49.67.67,0,0,0,.5.21.7.7,0,0,0,.7-.7A.65.65,0,0,0,31.64,9.48Z"></path>
                        <polygon
                            points="29.3 4.83 27.86 4.83 25.73 6.96 25.73 0 24.65 0 24.65 10.47 25.73 10.47 25.73 8.28 26.11 7.91 28.12 10.47 29.53 10.47 26.88 7.17 29.3 4.83"></polygon>
                    </svg>
                </a>

                <button className="navbar-toggler" type="button" data-toggle="navbarToggler">
                    <span className="navbar-toggler-icon ml-4"/>
                </button>

                {/*Mobile sidebar*/}
                <div className="navbar-inner d-flex justify-content-center">
                    <nav>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link  nav-line cursor-pointer"  onClick={() => handleLinkClick("/studentenkorting")}>{initStrings.element1}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link  nav-line cursor-pointer"  onClick={() => handleLinkClick("/cashback/studentenkorting")}>{initStrings.element2}</a>
                            </li>

                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link  nav-line cursor-pointer"  onClick={() => handleLinkClick("/bijbaan")}>{initStrings.element3}</a>*/}
                            {/*</li>*/}
                            <li className="nav-item">
                                <a className="nav-link  nav-line cursor-pointer"  onClick={() => handleLinkClick("/download-app")}>{initStrings.element5}</a>
                            </li>


                            <li className="nav-item dropdown ">
                                <a className="nav-link dropdown-toggle nav-line cursor-pointer"  onClick={() => handleLinkClick("/")} id="navbarDropdownMenuLink"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {initStrings.element6}
                                </a>
                                <ul className="dropdown-menu shadow " aria-labelledby="navbarDropdownMenuLink">
                                    {!hasAValue(authDataRS) && <li>
                                        <a className="dropdown-item nav-line pt-3"
                                           onClick={()=> {
                                            if(s) {
                                                dispatch(updateShowLoginLinkModal(true))
                                                return
                                            }
                                            dispatch(updateShowAuthModalRS(true))
                                           }}>{initStrings.subElements6.item0}</a>
                                    </li>}

                                    {hasAValue(authDataRS) && <li>
                                        <a className="dropdown-item nav-line pt-3 cursor-pointer"  onClick={() => handleLinkClick("/account")}>{initStrings.subElements6.item7}</a>
                                    </li>}

                                    <li>
                                        <a className="dropdown-item nav-line cursor-pointer" onClick={() => handleLinkClick("/studentenkorting-geven")}>{initStrings.subElements6.item1}</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item nav-line cursor-pointer" onClick={() => handleLinkClick("/wie-zijn-wij")}>{initStrings.subElements6.item2}</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item nav-line  cursor-pointer" onClick={() => handleLinkClick("/partners-van-knaek")}>{initStrings.subElements6.item3}</a>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <a className="dropdown-item nav-line cursor-pointer"*/}
                                    {/*       href="https://knaekbox.nl/en">{initStrings.subElements6.item4}</a>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a className="dropdown-item nav-line cursor-pointer" onClick={() => handleLinkClick("/contact")}>{initStrings.subElements6.item5}</a>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <a className="dropdown-item nav-line"*/}
                                    {/*       to="/download-app">{initStrings.subElements6.item8}</a>*/}
                                    {/*</li>*/}
                                    {hasAValue(authDataRS) && <li>
                                        <a className="dropdown-item nav-line"
                                           onClick={()=> {
                                               dispatch(updateAuthDataNEWRS(null))
                                               dispatch(updateEmailInModal(null));
                                               afterLogoutAlertFunction()
                                           }}>{initStrings.subElements6.item6}</a>
                                    </li>}
                                </ul>

                            </li>

                            <li className={"nav-item d-flex justify-content-center align-items-center m-2"}>
                                <div className="d-flex justify-content-center align-items-center btn-transparent btn-homepage-appstore">
                                    <a className="position-relative"
                                       onClick={()=> switchLanguage(appLanguage.be)}>
                                        <img src="https://cms.it.knaek.com/uploads/nederlands_9928ec3018.svg"
                                             alt="Nederlands" className="position-relative" width="25" height="17"/>
                                    </a>
                                    <a className="position-relative"
                                       onClick={()=> switchLanguage(appLanguage.en)}>
                                        <img src="https://cms.it.knaek.com/uploads/english_2b352b017b.svg"
                                             alt="Nederlands" className="position-relative" width="25" height="17"/>
                                    </a>
                               
                                </div>
                            </li>
                            <li className={"nav-item ml-2 d-flex justify-content-center align-items-center m-2"}>
                                <div
                                    className="d-flex justify-content-center align-items-center btn-transparent btn-homepage-appstore">
                                    <a className="position-relative nav-link  nav-line cursor-pointer" onClick={() => handleLinkClick("/bestellen")}>{initStrings.element4}</a>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}
