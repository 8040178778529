import {dateFormat} from "./SharedFunctions";

export const mapStatusBadge = (status, colorMap) => {
    const normalizedStatus = status?.toLowerCase();
    return colorMap[normalizedStatus] || 'default';
};

export const mapStatusLabel = (status, labelMap) => {
    const normalizedStatus = status?.toLowerCase();
    return labelMap[normalizedStatus] || 'Unknown';
};

export const formatOptionalDate = (date) => (date ? dateFormat(date) : 'N/A');