import React, {useEffect} from "react";
import i18n from "i18n-js";
import {useDispatch, useSelector} from "react-redux";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords, WEBSITE_TYPE} from "../../../config/Constants";
import {updateLanguageRS} from "../../../redux/reduxActions/reduxStoreActions";
import ReviewsHeader from "../../HeaderSection/ReviewsHeader";
import {Helmet} from "react-helmet";

export default function ThanksForTheReview() {

    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const dispatch = useDispatch();
    i18n.locale = appLanguageRS;

    useEffect(() => {
        if (!appLanguageRS) {
            if (window.location.toString().toLowerCase().includes("knaek.com")) {
                i18n.locale = "en";
                dispatch(updateLanguageRS("en"))
            } else if (window.location.toString().toLowerCase().includes("knaek.nl")) {
                i18n.locale = "nl";
                dispatch(updateLanguageRS("nl"))
            } else if (window.location.toString().toLowerCase().includes("knaek.be")) {
                i18n.locale = "be";
                dispatch(updateLanguageRS("be"))
            } else {
                i18n.locale = WEBSITE_TYPE;
                dispatch(updateLanguageRS(WEBSITE_TYPE))
            }
        } else {
            i18n.locale = appLanguageRS;
        }
    }, [appLanguageRS])

    const initStrings = {
        thank_you_for_placing_your_rating: stringsTranslate(appWords.thank_you_for_placing_your_rating),
        close_this_page: stringsTranslate(appWords.close_this_page),
        thank_you_for_your_rating: stringsTranslate(appWords.thank_you_for_your_rating),

    }


    return (
        <div className={"homepage-4 pt-5 bg-trust-pilot"}>
            <Helmet>
                <title>Review</title>
            </Helmet>
            <div className={"container"}>
                <ReviewsHeader switchLanguage={(language) => switchLanguage(language)} />
                <div className="row justify-content-center align-content-center bg-trust-pilot h-100vh mt-5">
                <div className="col-12 bg-trust-pilot text-center">
                    <h4 className="text-dark">{initStrings.thank_you_for_placing_your_rating} </h4>
                    <h3 className="text-dark">{initStrings.close_this_page} </h3>

                </div>
                </div>
            </div>
        </div>
    );

    function switchLanguage(language) {
        dispatch(updateLanguageRS(language))
    }
}
