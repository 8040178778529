import React from 'react';
import {Modal} from "react-bootstrap";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";

export default function CouponPurchaseModalComponent({onClose, amount, startDate, endDate}) {

    let initStrings = {
        payment_is_successful: stringsTranslate(appWords.payment_is_successful),
        your_coupon_code: stringsTranslate(appWords.your_coupon_code),
        extra_alert_for_amazon: stringsTranslate(appWords.extra_alert_for_amazon),
        this_code_will_also_be_sent_to_your_email: stringsTranslate(appWords.this_code_will_also_be_sent_to_your_email),
        valid_from: stringsTranslate(appWords.valid_from),
        until: stringsTranslate(appWords.until),


    }

    return (
        <Modal className="modal fade radius-15 mt-4" size={"lg"} onHide={onClose} show={true}>
            <div className="radius-15" role="document">
                <div className="radius-15">
                    <div className={"radius-15 pt-3 bg-light"}>
                        <div className="card-header bg-light border-0 d-flex justify-content-end cursor-pointer"
                             onClick={onClose}>
                            <svg className=" mt-3 mr-3" width="15" height="15" viewBox="0 0 32 32">
                                <path fill="black"
                                      d="M31.29,27.48a2.7,2.7,0,0,1-3.81,3.81L16,19.83,4.52,31.29A2.7,2.7,0,0,1,.71,27.48L12.17,16,.71,4.52A2.7,2.7,0,0,1,4.52.71L16,12.17,27.48.71a2.7,2.7,0,0,1,3.81,3.81L19.83,16Z"/>
                            </svg>
                        </div>
                        <div className="modal-body radius-15">
                            <div className="card border-top border-0 mx-2 bg-light">
                                <div
                                    className="card-header text-center bg-light border-0 d-flex justify-content-center">
                                    <h1 className="card-title">{initStrings.payment_is_successful}</h1>
                                </div>
                                <div className="cashback-bg-model pb-3 pt-3 radius-15">
                                    <div className="text-left justify-content-center align-content-center radius-15">
                                        <h4 className="mr-5 ml-3 text-center text-knaek-primary">
                                            {initStrings.your_coupon_code}
                                        </h4>

                                        <ul className="mb-3 ml-4 mr-3">

                                            <li className={"pt-2"}>
                                                <span className="text-knaek-primary">
                                                    {initStrings.extra_alert_for_amazon}
                                                </span>
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                                <div
                                    className="card-header text-center bg-light border-0 d-flex justify-content-center">
                                    <p className="card-title">{initStrings.this_code_will_also_be_sent_to_your_email}</p>
                                </div>
                                <div
                                    className="card-header text-center bg-light border-0 d-flex justify-content-center">
                                    <h5 className="card-title">({initStrings.valid_from} {startDate} {initStrings.until} )</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );


}
