import React, {useEffect} from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {loginWithOneTimeTokenCall} from "../../api/ApiCalls";
import {useParams, useLocation} from "react-router-dom";
import {tokenErrorAlertFunction} from "../../uitls/AlertFunctions";
import {Spinner} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {updateAuthDataNEWRS, updateShowLoginLinkModal} from "../../redux/reduxActions/reduxStoreActions";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';
import {Helmet} from "react-helmet";
import {storageKeys} from "../../config/storageKeysConstants";


export default function ToProfileAfterLogin() {
    const dispatch = useDispatch();
    const {token} = useParams();
    const handleLinkClick = useHandleLinkClick();
    const login_sl = stringsTranslate(appWords.login_sl);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const redirectFromQuery = queryParams.get('redirectUrl');

    useEffect(() => {
        
        dispatch(updateShowLoginLinkModal(false));
        oneTimeTokenLogin().then()
    }, [])

    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
            <Helmet>
                <title>Knaek - {login_sl}</title>
            </Helmet>
            <Spinner animation="border" variant="primary"/>
        </div>
    );

    async function oneTimeTokenLogin() {
        function invalidLinkFeedback() {
            tokenErrorAlertFunction(stringsTranslate(appWords.link_expired), "")
            setTimeout(() => {
                handleLinkClick('/')
            }, 3000);
        }

        try {

            const response = await loginWithOneTimeTokenCall({token});
            if (!response) {
                invalidLinkFeedback();
                return;
            }
            dispatch(updateAuthDataNEWRS({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                city_id: response.data.city_id,
            }));
            const goalMappings = {
                "APP_TO_WEB_REDIRECTION": '/account',
                "LOGIN_BY_EMAIL": '/account',
                "APP_TO_WEB_REDIRECTION_CLAIM_FORM": '/account/online-bespaard#openClaim',
                "APP_TO_WEB_REDIRECTION_CLAIM_STATUS": '/account/online-bespaard#claims'
            };
            const goal = response.data?.goal?.toString();
            const matchingKey = Object.keys(goalMappings).find(key => key === goal);
            const defaultUrl = '/';
            const goalBasedRedirect = matchingKey ? goalMappings[matchingKey] : defaultUrl;
            const finalRedirectUrl = redirectFromQuery || goalBasedRedirect;

            // Clear the redirect URL from sessionStorage
            sessionStorage.removeItem(storageKeys.REDIRECT_URL);

            setTimeout(() => {
                handleLinkClick(finalRedirectUrl);
            }, 500);

        } catch (e) {
            invalidLinkFeedback();
        }
    }
}
