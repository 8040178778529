import React from 'react';
import {appLanguage, WEBSITE_TYPE} from "../../../config/Constants";
import TermsAndConditionsContentBE from "./TermsAndConditionsContentBE";
import TermsAndConditionsContentNL from "./TermsAndConditionsContentNL";

export default function TermsAndConditionsContent() {

    return (
        <>
            {WEBSITE_TYPE === appLanguage.nl ? (
                <TermsAndConditionsContentNL />
            ) : (
                <TermsAndConditionsContentBE />
            )}
        </>
    );
}


