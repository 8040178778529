import React from 'react';
import {appColors} from "../../config/Constants";
import {useSelector} from "react-redux";

export default function StoreDiscountCardSaved({item, savingDetails}) {
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    let contentLanguage = appLanguageRS === "en"? "en": "nl"
    return (
        <tr className={"col-10 d-flex flex-wrap justify-content-between border-bottom py-2"}>
            <div className={"row w-100"}>
                <section className={"col-6 col-md-4 col-lg-4"}>
                    <svg width="20" height="20" viewBox="0 0 32 32" className={""}>
                        <path
                            fill={appColors.knaek_valid }
                            d="M28,2H10.67a4,4,0,0,0-4,4v6.67H4a4,4,0,0,0-4,4V26a4,4,0,0,0,4,4H21.33a4,4,0,0,0,4-4V19.33H28a4,4,0,0,0,4-4V6A4,4,0,0,0,28,2ZM22.67,18v8a1.33,1.33,0,0,1-1.34,1.33H4A1.32,1.32,0,0,1,2.67,26V16.67A1.33,1.33,0,0,1,4,15.33H21.33a1.34,1.34,0,0,1,1.34,1.34Zm6.66-2.67A1.33,1.33,0,0,1,28,16.67H25.33a4,4,0,0,0-4-4h-12V9.33h20Zm0-8.66h-20V6a1.33,1.33,0,0,1,1.34-1.33H28A1.32,1.32,0,0,1,29.33,6Zm-20.66,14V22a1.33,1.33,0,0,1-1.34,1.33H6A1.32,1.32,0,0,1,4.67,22V20.67A1.33,1.33,0,0,1,6,19.33H7.33A1.34,1.34,0,0,1,8.67,20.67Z"></path>
                    </svg>
                </section>

                <h5 className="text-left col-6 col-md-4 col-lg-4">{item.localization[contentLanguage].title}</h5>
                <p className="text-left text-lg-right text-md-right col-6 col-md-4 col-lg-4">€{parseFloat(savingDetails.saved).toFixed(2)}</p>
            </div>
        </tr>
    );
}
