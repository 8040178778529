import React from 'react';
import {appLanguage, WEBSITE_TYPE} from "../../../config/Constants";
import PrivacyContentNL from "./PrivacyContentNL";
import PrivacyContentBE from "./PrivacyContentBE";

export default function PrivacyContent() {

    return (
        <>
            {WEBSITE_TYPE === appLanguage.nl ? (
                <PrivacyContentNL />
            ) : (
                <PrivacyContentBE />
            )}
        </>
    );
}


