import {fetchMyPayoutsSnipTechCall, fetchMyTicketsSnipTechCall, fetchMyTransactionSnipTechCall} from "../api/ApiCalls";
import {formatOptionalDate, mapStatusBadge, mapStatusLabel} from "../uitls/dataTransformUtils";
import {stringsTranslate} from "../locales/CustomTranslater";
import {appWords} from "../config/Constants";

const initStrings = {
    knaek_approved: stringsTranslate(appWords.knaek_approved),
    knaek_pending: stringsTranslate(appWords.knaek_pending),
    knaek_declined: stringsTranslate(appWords.knaek_declined),
    knaek_payout: stringsTranslate(appWords.knaek_payout),
    knaek_open: stringsTranslate(appWords.knaek_open),
    knaek_answered: stringsTranslate(appWords.knaek_answered),
    knaek_closed: stringsTranslate(appWords.knaek_closed),
    knaek_new: stringsTranslate(appWords.knaek_new),
    cashbackAmountInvalid: stringsTranslate(appWords.cashbackAmountInvalid),
    purchaseNotRegistered: stringsTranslate(appWords.purchaseNotRegistered),
    purchaseRejected: stringsTranslate(appWords.purchaseRejected),
};
export async function fetchPayoutsSnipTech() {
    try {
        const payoutsResponse = await fetchMyPayoutsSnipTechCall();
        return payoutsResponse.map(mapPayoutsApiData);
    } catch (error) {
        console.error("Error fetching payouts:", error);
        throw error; // Propagate the error to handle it at the call site
    }
}
export async function fetchTransactionSnip() {
    try {
        const payoutsResponse = await fetchMyTransactionSnipTechCall();
        return payoutsResponse.map(mapTransactionApiData);
    } catch (error) {
        console.error("Error fetching transactions:", error);
        throw error;
    }
}
export async function fetchTicketsSnipTech() {
    try {
        const payoutsResponse = await fetchMyTicketsSnipTechCall();
        return payoutsResponse.map(mapTicketsApiData);
    } catch (error) {
        console.error("Error fetching payouts:", error);
        throw error;
    }
}
export function mapPayoutsApiData(item) {

    return {
        ...item,
        amount: item.amount,
        statusBadge: mapStatusBadge(item.status, {
            confirmed: 'success',
            rejected: 'danger',
            pending: 'info',
        }),
        statusLabel: mapStatusLabel(item.status, {
            confirmed: initStrings.knaek_approved,
            rejected: initStrings.knaek_declined,
            pending: initStrings.knaek_pending,
        }),
        creationDate: formatOptionalDate(item.creationDate),
        paidDate: formatOptionalDate(item.paidDate),
    };
}


export function mapTransactionApiData(item) {

    return {
        ...item,
        cashback: '€' + item.cashback,
        statusBadge: mapStatusBadge(item.status, {
            confirmed: 'success',
            rejected: 'danger',
            pending: 'info',
            claimed: 'warning',
        }),
        statusLabel: mapStatusLabel(item.status, {
            confirmed: initStrings.knaek_approved,
            rejected: initStrings.knaek_declined,
            pending: initStrings.knaek_pending,
            claimed: initStrings.knaek_payout,
        }),
        converted_at: formatOptionalDate(item.converted_at),
    };
}

export function mapTicketsApiData(item) {

    return {
        ...item,
        cashback: '€' + item.cashback,
        statusBadge: mapStatusBadge(item.status, {
            'new': 'info',
            'open': 'success',
            'answered': 'warning',
            'closed': 'danger'
        }),
        statusLabel: mapStatusLabel(item.status, {
            'new': initStrings.knaek_new,
            'open': initStrings.knaek_open,
            'answered': initStrings.knaek_answered,
            'closed': initStrings.knaek_closed
        }),
        typeLabel: mapStatusLabel(item.type?.toString().toLowerCase(), {
            'cashbackamountinvalid': initStrings.cashbackAmountInvalid,
            'purchasenotregistered': initStrings.purchaseNotRegistered,
            'purchaserejected': initStrings.purchaseRejected
        }),
        creationDate: formatOptionalDate(item.creationDate),
    };
}