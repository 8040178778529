import React from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {FaTiktok} from "react-icons/fa";
import { getCurrentYear } from '../../uitls/SharedFunctions';

export default function FooterBottomSection() {

    const initStrings = {
        "image": "/knaek/logo.png",
        "linkText_4": stringsTranslate(appWords.all_rights_reserved),
        "iconList": [

            {
                "id": 1,
                "className": "facebook m-2",
                "iconClass": "fab fa-facebook-f h3",
                "link": "https://www.facebook.com/knaek.nl/"
            }, {
                "id": 2,
                "className": "instagram m-2",
                "iconClass": "fab fa-instagram h3",
                'link': 'https://www.instagram.com/knaek.nl/'
            },
            {
                "id": 3,
                "className": "google-play m-2",
                "link": "https://play.google.com/store/apps/details?id=net.evect.knaek",
                "iconClass": "fab fa-google-play h3"
            },
            {
                "id": 4,
                "className": "app-store m-2",
                "link": "https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone",
                "iconClass": "fab fa-app-store h3"
            }
        ],

    }


    return (
        <footer className="footer-area">
            {/* Footer Bottom */}
            <div className="footer-bottom mx-3">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between">
                        <div className="col-12 mt-3 pt-3 order-0">
                            <div className={"copyright-area"}></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-lg-1 order-md-1 text-center">
                            {/* Copyright Area */}
                            <div
                                className="button-group store-buttons store-black d-flex flex-wrap justify-content-center justify-content-lg-start justify-content-md-start">
                                {/* Copyright Left */}
                                <div className="mt-2  pt-2 text-center text-lg-left text-md-left">© Knaek {getCurrentYear()}. {initStrings.linkText_4}.</div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-end justify-content-center justify-content-lg-end justify-content-md-end  order-1 order-lg-2 order-md-2">
                            {/* Footer Items */}
                            <div className="footer-items">
                                <div className="social-icons2 button-group store-buttons store-black d-flex flex-wrap justify-content-center justify-content-lg-end justify-content-md-end">
                                    <a className={"tiktok m-2"} href={"https://www.tiktok.com/@knaek"} target={"_blank"}><FaTiktok className={"h3"}/></a>
                                    {initStrings.iconList.map((item, idx) => {
                                        return (<a key={`fi_${idx}`} className={item.className} href={item.link} target={"_blank"}>
                                            <i className={item.iconClass}/>
                                        </a>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
