import React, {useEffect, useState} from 'react';
import KnaekPrimaryButton from "../AppComponents/KnaekPrimaryButton";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appLanguage, appWords, WEBSITE_TYPE} from "../../config/Constants";
import SnipTechOnlineDiscountCard from "../AppComponents/SniptechOnlineDiscountCard";
import {getAllSnipTechOnlineDiscountsCall} from "../../api/ApiCalls";
import {updateOnlineDiscountsSnipTechRS, updateSnipTechStoresCountRS} from "../../redux/reduxActions/reduxStoreActions";
import {useDispatch} from "react-redux";

export default function SnipTechListItemsOnlineDiscounts({filters, searchQuery}) {
    let initStrings = {
        load_more_button: stringsTranslate(appWords.load_more_button)
    }

    const [items, setItems] = useState([]);
    const [nextPage, setNextPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1000);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleScroll = () => {
            if (!isLoading && nextPage <= totalPages) {
                const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
                if (isAtBottom) {
                    loadMoreItems();
                }
            }
        };
        // Add event listener when the component mounts
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isLoading, nextPage, totalPages]);


    useEffect(() => {
        resetState();
        getSnipTechOnlineDiscounts(1, false);
    }, [filters, searchQuery]);

    function resetState() {
        setItems([]);
        setIsLoading(false);
        setNextPage(1);
        setTotalPages(1000);
    }

    return (
        <div className="col-lg-10 col-12 text-left border-top border-bottom">
            {
                items.length > 0 && (
                    <section className={"row justify-content-center pt-5"}>
                        {items.map((item, i) => (
                            <div className="store-discount-card" key={i}>
                                <SnipTechOnlineDiscountCard item={item}/>
                            </div>
                        ))}
                        <br/>
                    </section>
                )
            }
            {
                isLoading && (
                    <div className="text-center m-5">
                        <div className="spinner-border text-knaek-secondary">
                        </div>
                    </div>
                )
            }
            {
                nextPage < totalPages && !isLoading && (
                    <div className={"col-12 d-flex justify-content-center align-content-center align-items-center"}>
                        <KnaekPrimaryButton
                            className={"col-3 mt-2 mb-4"}
                            onClick={() => loadMoreItems()}
                            title={initStrings.load_more_button}
                        />
                    </div>
                )
            }
        </div>
    );

    function loadMoreItems() {
        if (isLoading || nextPage >= totalPages) {
            return;
        }
        getSnipTechOnlineDiscounts(nextPage, true);
    }

    function getFilter() {
        const culture = WEBSITE_TYPE === appLanguage.nl ? 'culture=nl-NL' : 'culture=nl-BE';
        const categoryIds = filters.category?.ids ?? [];
        const weightFilter = filters.webWeightLT ? `webWeightLT=${filters.webWeightLT}` : 'webWeightLT=101';
        const apiFilters = [culture, weightFilter, ...categoryIds.map(id => `categoryId=${id}`)];
        if (searchQuery?.length) {
            searchQuery = encodeURIComponent(searchQuery);
            apiFilters.push(`nameWildcard=${searchQuery}`);
        }
        return apiFilters.join('&');
    }

    function getSnipTechOnlineDiscounts(page, loadMore = false) {
        setIsLoading(true);
        getAllSnipTechOnlineDiscountsCall(page, 30, getFilter()).then(result => {
            if (loadMore) {
                setItems([...items, ...result.items]);
            } else {
                setItems(result.items);
            }
            setNextPage(result.page + 1);
            setTotalPages(result.totalPages);
            dispatch(updateSnipTechStoresCountRS(result.count));
            dispatch(updateOnlineDiscountsSnipTechRS([...items, ...result.items]));
        }).catch(error => {
        }).finally(() => {
            setIsLoading(false);
        });
    }
}
