import React, {useState} from 'react';
import {Nav} from "react-bootstrap";
import FAQ from "./FAQ";
import HowCashbackWorks from "./HowCashbackWorks";
import {appWords} from "../../../config/Constants";
import {stringsTranslate} from "../../../locales/CustomTranslater";

export default function KnaekBEContactSection() {
    const initStrings = {
        heading1: stringsTranslate(appWords.contact_heading_text_one),
        subheading1: stringsTranslate(appWords.contact_subheading_text_one),
        heading: stringsTranslate(appWords.contact_heading_text_two),
        subheading: stringsTranslate(appWords.contact_subheading_text_two),
        content_title: stringsTranslate(appWords.contact_content_title),
        title1: stringsTranslate(appWords.contact_title_1),
        contact1: "Admiralengracht 60H 1057 GB, Amsterdam",
        playImg1: "/assets/images/studentkorting/people.png",
        title2: stringsTranslate(appWords.contact_title_2),
        contact2: "Admiralengracht 60H 1057 GB, Amsterdam",
        playImg2: "/assets/images/studentkorting/people.png",
        title3: stringsTranslate(appWords.contact_title_3),
        contact3: "+31 (0)20 - 716 5771",
        playImg3: "/assets/images/studentkorting/people.png",
        title4: stringsTranslate(appWords.contact_title_4),
        timing_contact: stringsTranslate(appWords.timing_contact_hours),
        playImg4: "/assets/images/studentkorting/people.png",
        title5: stringsTranslate(appWords.contact_title_5),
        contact5:{
            text2: "BTWnr: BE0790327195",
            text3: stringsTranslate(appWords.bank_text),
        } ,
        playImg5: "/assets/images/studentkorting/people.png",
        title6: stringsTranslate(appWords.contact_title_6),
        title7: stringsTranslate(appWords.contact_title_7),
        contact6: stringsTranslate(appWords.contact_mail_us),
        contact7: stringsTranslate(appWords.contact_message_us),
        mapImg: "/assets/images/maps.png",
        tapHeading1: stringsTranslate(appWords.contact_tap_heading_1),
        tapHeading2: stringsTranslate(appWords.contact_tap_heading_2),
    }

    const [showFAQ, setShowFAQ] = useState(true);


    return (
        <section className="section mt-1 mb-5 pb-5">
            <div className="container-fluid">
                <div className="row mt-5 ml-2 mr-2 mt-3 pt-3">
                    <div className="col-lg-6 col-md-12">
                        <section>
                            <h1 className="mt-2">{initStrings.heading1}</h1>
                            <p className="mt-3" dangerouslySetInnerHTML={{__html: initStrings.subheading1}}></p>
                            <div className="row col-12 mt-3">
                                <div className="col-12 my-3 pl-0 ml-0 border-0 text-left faq-tab-focus h4 font-weight-bold pb-4 mb-4">
                                    <h2 className="mb-2">{initStrings.content_title}</h2>
                                </div>

                                <div className="col-lg-6 col-md-6 my-3 mt-4 pt-1 ml-0 pl-3">
                                    <a className="module-image d-inline-block rounded-circle text-center mb-2">
                                        <svg width="40" height="40" viewBox="0 0 32 32" className="svg-secondary">
                                            <path
                                                d="M26.72,3.2h-.853V2.773a2.774,2.774,0,0,0-5.547,0V3.2H11.68V2.773a2.827,2.827,0,0,0-5.653,0V3.2H5.28A2.805,2.805,0,0,0,2.293,5.973V29.12A2.868,2.868,0,0,0,5.173,32H26.827a2.868,2.868,0,0,0,2.88-2.88V6.08A3.033,3.033,0,0,0,26.72,3.2Zm-4.587-.427a.842.842,0,0,1,.854-.853.941.941,0,0,1,.96.853V5.12a.956.956,0,0,1-.96.96.934.934,0,0,1-.854-.96Zm-14.293,0A.934.934,0,0,1,8.8,1.92a.841.841,0,0,1,.853.853V5.12a.94.94,0,0,1-.853.96.956.956,0,0,1-.96-.96ZM27.787,29.12a.956.956,0,0,1-.96.96H5.28a1.053,1.053,0,0,1-1.067-.96v-.96l1.067.213H26.827l.96-.213Zm0-3.84a1.053,1.053,0,0,1-.96,1.067H5.28A1.07,1.07,0,0,1,4.213,25.28h0V6.08a.956.956,0,0,1,.96-.96h.854a2.579,2.579,0,0,0,2.56,2.773H8.8A2.824,2.824,0,0,0,11.573,5.12h8.64a2.824,2.824,0,0,0,2.774,2.773,2.579,2.579,0,0,0,2.773-2.56V5.12h.853a.956.956,0,0,1,.96.96ZM15.947,8.533A7.467,7.467,0,1,0,23.413,16,7.421,7.421,0,0,0,15.947,8.533Zm0,13.227A5.76,5.76,0,1,1,21.707,16h0A5.736,5.736,0,0,1,15.947,21.76Zm3.306-6.507H16.8V12.8a.747.747,0,1,0-1.493,0V16a.581.581,0,0,0,.533.747h3.52A.729.729,0,0,0,20.107,16,.906.906,0,0,0,19.253,15.253Z"></path>
                                        </svg>
                                    </a>
                                    <h4 className="mt-1 pl-2">{initStrings.title4}</h4>
                                    <p className="pl-2" >{initStrings.timing_contact}</p>
                                </div>

                                <div className="col-lg-6 col-md-6 my-3 ml-0 pl-3">
                                    <a className="module-image d-inline-block rounded-circle text-center mb-2">
                                        <svg width="40" height="40" viewBox="0 0 32 32" className="svg-secondary">
                                            <path
                                                d="M26.2,6H5.8A3.5,3.5,0,0,0,2.3,9.5v13A3.5,3.5,0,0,0,5.8,26H26.2a3.5,3.5,0,0,0,3.5-3.5V9.5A3.5,3.5,0,0,0,26.2,6ZM5.8,8H26.2a1.4,1.4,0,0,1,.9.3l-9.7,7.1a2.4,2.4,0,0,1-2.8,0L4.9,8.3A1.4,1.4,0,0,1,5.8,8ZM27.7,22.5A1.5,1.5,0,0,1,26.2,24H5.8a1.5,1.5,0,0,1-1.5-1.5V10.4l9.1,6.7a5,5,0,0,0,2.6.8,5,5,0,0,0,2.6-.8l9.1-6.7Z"></path>
                                        </svg>
                                    </a>
                                    <h4 className="mt-1 pl-2">{initStrings.title6}</h4>
                                    <p className="pl-2" >{initStrings.contact6} <a href={"mailto:info@knaek.be"}>info@knaek.be</a></p>
                                </div>

                                <div className={"col-lg-12 col-md-12 my-3 ml-0 pl-3"}>
                                    <div className="module-image d-inline-block rounded-circle text-center mb-2">
                                        <svg width="40" height="40" viewBox="0 0 32 32" className="svg-secondary">
                                            <path
                                                d="M26.522.006H6.659a3.114,3.114,0,0,0-3.22,3.006v2.04A2.687,2.687,0,0,0,.862,7.629a2.591,2.591,0,0,0,2.469,2.576v3.221a2.577,2.577,0,0,0,0,5.154v3.113a2.579,2.579,0,0,0,0,5.154v2.039A3.116,3.116,0,0,0,6.445,32H26.414a4.934,4.934,0,0,0,4.832-4.724V4.73A4.766,4.766,0,0,0,26.522.006ZM2.794,7.629a.734.734,0,0,1,.752-.752h1.5a.752.752,0,1,1,0,1.5h-1.5A.734.734,0,0,1,2.794,7.629Zm0,8.481a.734.734,0,0,1,.752-.751h1.5a.752.752,0,1,1,0,1.5h-1.5a.734.734,0,0,1-.752-.752Zm0,8.375a.734.734,0,0,1,.752-.752h1.5a.752.752,0,1,1,0,1.5h-1.5c-.43-.107-.752-.322-.752-.751ZM5.371,29.1v-2.04a2.577,2.577,0,1,0,0-5.153V18.794a2.577,2.577,0,1,0,0-5.153V10.42A2.745,2.745,0,0,0,7.733,7.843,2.973,2.973,0,0,0,5.371,5.052V3.119A1.127,1.127,0,0,1,6.552,1.938H9.129V30.282H6.659c-.751.108-1.181-.429-1.288-1.181Zm24.049-1.61a2.863,2.863,0,0,1-2.9,2.791H11.061V1.938H26.414a2.863,2.863,0,0,1,2.9,2.792Zm-5.046-7.408a.966.966,0,1,1-.966.966C23.3,20.405,23.73,20.083,24.374,20.083Zm-2.469-4.832H19.972L17.718,17.4V9.346h-1.5V21.693h1.5V18.9l2.254,2.791h1.933l-2.9-3.758Z"></path>
                                        </svg>
                                    </div>
                                    <h4 className="mt-1 pl-2">{initStrings.title5}</h4>
                                    <p className="pl-2" >{initStrings.contact5.text1}</p>
                                    <p className="pl-2" >{initStrings.contact5.text2}</p>
                                    <p className="pl-2">{initStrings.contact5.text3}</p>
                                </div>


                            </div>

                        </section>

                    </div>
                    <div className="col-lg-6 col-md-12 w-100 ">
                        <section className="w-100 h-100 ">
                            <h1 className="mt-2 ml-3">{initStrings.heading}</h1>
                            <p className="mt-3 ml-3 pr-2">{initStrings.subheading}</p>
                            <div className="w-100">
                                <Nav justify className="border-0 mt-3 pt-2" variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link className={`${showFAQ && "faq-tab-focus" } ml-0 pl-0  border-0 text-left faq-tab h4 font-weight-bold pb-4`} onClick={() => setShowFAQ(true)}>
                                            <h2 className={"d-none d-lg-block"}>{initStrings.tapHeading1}</h2>
                                            <h4 className={"d-block d-lg-none"}>{initStrings.tapHeading1}</h4>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className={`${!showFAQ && "faq-tab-focus" }  ml-0 pl-0  border-0 text-left faq-tab h4 font-weight-bold  pb-4`} onClick={() => setShowFAQ(false)}>
                                            <h2 className="d-none d-lg-block">{initStrings.tapHeading2}</h2>
                                            <h4 className="d-block d-lg-none">{initStrings.tapHeading2}</h4>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>

                            {showFAQ && <FAQ eventKey={true}/>}
                            {!showFAQ && <HowCashbackWorks eventKey={false}/>}

                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
}
