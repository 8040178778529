import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import routeMapping from '../routers/routeMapping';
import {useSelector} from 'react-redux';

const useRouteInterceptor = () => {
//  if we want to change lang based on app local

//  if we want to change lang based on .com

  const language = useSelector((state) => state.appLanguageRS);
 
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const mapRoutes = routeMapping[language];
    const currentPath = location.pathname;
 
    const segments = currentPath.split('/').filter(Boolean);
 
    const translatedSegments = segments.map(segment => mapRoutes[segment] || segment);
 
    const translatedPath = '/' + translatedSegments.join('/');

    if (translatedPath !== currentPath) {
      history.replace(translatedPath);
    }
  }, [location, language, history]);
};

export default useRouteInterceptor;
