import React from 'react';
import FooterNL from './FooterNL';  // Adjust the import path
import FooterBE from './FooterBE';
import {appLanguage, WEBSITE_TYPE} from "../../config/Constants";  // Adjust the import path


export default function FooterSection() {
    return (
        <>
            {WEBSITE_TYPE === appLanguage.nl ? (
                <FooterNL />
            ) : (
                <FooterBE />
            )}
        </>
    );
}
