import i18n from 'i18n-js';

import nlTranslations from './translations.json';
import beTranslations from './translations-be.json';

const website = process.env.REACT_APP_WEBSITE || 'nl'; // Default to 'nl'
const translations = website === 'be' ? beTranslations : nlTranslations;
const en = {};
const localLang = {}; // 'be' for BE, 'nl' for NL
console.log(website)
Object.keys(translations).forEach(key => {
    en[key] = translations[key].en;
    localLang[key] = translations[key][website === 'be' ? 'be' : 'nl'];
});

i18n.translations = {
    en: en,
    [website]: localLang,
}

i18n.fallbacks = {languageTag: "en", isRTL: false};

export function stringsTranslate(name,vars={}) {
    return i18n.t(name,vars);
}
