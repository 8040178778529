import React from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {FaTiktok} from "react-icons/fa";
import { getCurrentYear } from '../../uitls/SharedFunctions';
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';

export default function FooterBE() {
    const handleLinkClick = useHandleLinkClick();
    const initStrings = {
        "image": "/knaek/logo.png",
        "text": "",
        "linkText_1": stringsTranslate(appWords.select_your_city),
        "linkText_2":  stringsTranslate(appWords.links),
        "linkText_3": stringsTranslate(appWords.download),
        "linkText_4": stringsTranslate(appWords.all_rights_reserved),
        "iconList": [

            {
                "id": 1,
                "className": "facebook m-2",
                "iconClass": "fab fa-facebook-f h3",
                "link": "https://www.facebook.com/knaek.nl/"
            }, {
                "id": 2,
                "className": "instagram m-2",
                "iconClass": "fab fa-instagram h3",
                'link': 'https://www.instagram.com/knaek.be/'
            },
            {
                "id": 3,
                "className": "google-play m-2",
                "link": "https://play.google.com/store/apps/details?id=net.evect.knaek",
                "iconClass": "fab fa-google-play h3"
            },
            {
                "id": 4,
                "className": "app-store m-2",
                "link": "https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone",
                "iconClass": "fab fa-app-store h3"
            }
        ],
        "footerList_1": [
            {"id": 3, "name": "Aalst", "link": "/studentenkorting/Aalst"},
            {"id": 2, "name": "Antwerpen", "link": "/studentenkorting/Antwerpen"},
            {"id": 1, "name": "Brugge", "link": "/studentenkorting/Brugge"},
            {"id": 5, "name": "Brussel/Bruxelles", "link": "/studentenkorting/Brussel/Bruxelles"},
            {"id": 4, "name": "Gent", "link": "/studentenkorting/Gent"},
            {"id": 6, "name": "Hasselt", "link": "/studentenkorting/Hasselt"},
            {"id": 7, "name": "Kortrijk", "link": "/studentenkorting/Kortrijk"},
            {"id": 8, "name": "Leuven", "link": "/studentenkorting/Leuven"},
            {"id": 9, "name": "Liège", "link": "/studentenkorting/Liège"},
        ],
        "footerList_2": [{
            "id": 1, "text": stringsTranslate(appWords.terms_conditions), "link": "/algemene-voorwaarden"
        }, {
            "id": 3, "text": stringsTranslate(appWords.privacy_policy), "link": "/privacy"
        },
            {
                "id": 5, "text": stringsTranslate(appWords.our_partners), "link": "/partners-van-knaek"
            },
            {
                "id": 5, "text": stringsTranslate(appWords.contact_faq), "link": "/contact"
            },
            {
                "id": 5, "text": stringsTranslate(appWords.working_at_knaek), "link": "/vacatures"
            },
            {
                "id": 6, "text": stringsTranslate(appWords.unsubscribe), "link": "/opzeggen"
            }
        ],
        "footerList_3": [
        ],
        "footerList_4": [{
            "id": 1, "text": "Affiliate program"
        }, {
            "id": 2, "text": "Terms & Conditions"
        }, {
            "id": 3, "text": "Privacy Policy"
        }, {
            "id": 4, "text": "Refund Policy"
        }]
    }


    return (<div className={"mt-5"}>
        <div className="height-emulator d-none d-lg-block"/>
        <footer className="footer-area mt-5">
            {/* Footer Top */}
            <div className="footer-top ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-9 col-lg-9 mt-5">
                            {/* Footer Title */}
                            <h4 className="footer-title mb-3">{initStrings.linkText_1}</h4>
                            {/* Footer Items */}
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <ul className={"row"}>
                                        {initStrings.footerList_1.slice(0,6).map((item, idx) => {
                                            return (<li key={`flo_${idx}`} className="py-3 px-3 col-6 col-md-12 col-lg-12 ">
                                                <a className={'cursor-pointer'} onClick={() => handleLinkClick(item.link)}>{item.name}</a></li>);
                                        })}
                                    </ul>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <ul className={"row"}>
                                        {initStrings.footerList_1.slice(6,10).map((item, idx) => {
                                            return (<li key={`flo_${idx}`} className="py-3 px-3 col-6 col-md-12 col-lg-12 ">
                                                <a className={'cursor-pointer'} onClick={() => handleLinkClick(item.link)}>{item.name}</a></li>);
                                        })}
                                    </ul>
                                </div>




                            </div>
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-3 mt-5">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h4 className="footer-title mb-3">{initStrings.linkText_2}</h4>
                                <ul className={"row"}>
                                    {initStrings.footerList_2.map((item, idx) => {
                                        return (<li key={`flt_${idx}`} className="py-3 col-sm-12 col-md-12 col-lg-12 ">
                                            <a className={'cursor-pointer'} onClick={() => handleLinkClick(item.link)}>{item.text}</a>
                                        </li>);
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer Bottom */}

            <div className="footer-bottom mx-3 mb-5">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between">
                        <div className="col-12 mt-3 pt-3 order-0">
                            <div className={"copyright-area"}></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-lg-1 order-md-1 text-center">
                            {/* Copyright Area */}
                            <div
                                className="button-group store-buttons store-black d-flex flex-wrap justify-content-center justify-content-lg-start justify-content-md-start">
                                {/* Copyright Left */}
                                <div className="mt-2  pt-2 text-center text-lg-left text-md-left">© Knaek {getCurrentYear()}. {initStrings.linkText_4}.</div>
                                {/* Copyright Right */}
                                {/*<div className="copyright-right">Made with <i className="fas fa-heart" /> By <a href="/#">LesOpTijd</a></div>*/}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-end justify-content-center justify-content-lg-end justify-content-md-end  order-1 order-lg-2 order-md-2">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                {/*<p className="mt-2 mb-3">{initStrings.text}</p>*/}
                                {/* Social Icons */}
                                <div className="social-icons2 button-group store-buttons store-black d-flex flex-wrap justify-content-center justify-content-lg-end justify-content-md-end">
                                    <a className={"tiktok m-2"} href={"https://www.tiktok.com/@knaek"} target={"_blank"}><FaTiktok className={"h3"}/></a>
                                    {initStrings.iconList.map((item, idx) => {
                                        return (<a key={`fi_${idx}`} className={item.className} href={item.link} target={"_blank"}>
                                            <i className={item.iconClass}/>
                                        </a>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>);
}
