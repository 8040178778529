import React, {useEffect, useRef, useState} from 'react';
import { fetchSnipTechBalancesCall} from "../../../api/ApiCalls";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import { appWords} from "../../../config/Constants";
import ClaimCashbackComponent from "../../AppComponents/ClaimCashbackComponent";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import PayoutModalComponent from "../../AppComponents/PayoutModalComponent";
import {failedAlertFunction} from "../../../uitls/AlertFunctions";
import {useSelector} from "react-redux";
import {TableComponent} from "../../AppComponents/TableComponent";
import {
    fetchPayoutsSnipTech,
    fetchTicketsSnipTech,
    fetchTransactionSnip
} from "../../../services/onlineDiscountService";

export default function SavedOnlineDiscountsProfileSection() {
    const initStrings = {
        online_saved: stringsTranslate(appWords.online_saved),
        you_have_in_total_1: stringsTranslate(appWords.you_have_in_total_1),
        you_have_in_total_2: stringsTranslate(appWords.you_have_in_total_2),
        you_have_in_total_3: stringsTranslate(appWords.you_have_in_total_3),
        Where_is_my_transaction: stringsTranslate(appWords.Where_is_my_transaction),
        claimCashback: (stringsTranslate(appWords.claimCashback)).toString().toUpperCase(),
        payout: (stringsTranslate(appWords.payout)).toString().toUpperCase(),
        noBalanceForPayout: stringsTranslate(appWords.noBalanceForPayout),
        transactions_cc: stringsTranslate(appWords.transactions_cc),
        claims: stringsTranslate(appWords.claims),
        payouts: stringsTranslate(appWords.payouts),
        details: stringsTranslate(appWords.details),
        status: stringsTranslate(appWords.status),
        amount: stringsTranslate(appWords.amount),
        wallet: stringsTranslate(appWords.wallet),
        creation: stringsTranslate(appWords.creation),
        paid_at: stringsTranslate(appWords.paid_at),
        converted_at: stringsTranslate(appWords.converted_at),
        type: stringsTranslate(appWords.type),
        name: stringsTranslate(appWords.name),
        cashback: stringsTranslate(appWords.cashback),
        description: stringsTranslate(appWords.description),
        it_may_take_48_hours_before_your_cashback_is_visible_here: stringsTranslate(appWords.it_may_take_48_hours_before_your_cashback_is_visible_here),
    }

    const [showClaimModal, setShowClaimModal] = useState(false);
    const [sniptechTransactions, setSniptechTransactions] = useState([]);
    const [sniptechPayouts, setSniptechPayouts] = useState([]);
    const [sniptechTickets, setSniptechTickets] = useState([]);
    const [sniptechBalance, setSniptechBalance] = useState({})
    const [showPayoutModal, setShowPayoutModal] = useState(false);
    const [activeTabOffset, setActiveTabOffset] = useState(0);
    const tabSectionRef = useRef(null);


    useEffect(() => {
        checkAnchor()
        fetchMySniptechTransactionOnline().then()
        if (window.location.hash === '#claims') {
            tabSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
        }

    }, [])


    const appLanguageRS = useSelector(state => state.appLanguageRS);

    const formatNumber = (number) => {
        let contentLanguage = appLanguageRS === "en"? "en": "nl"
        return new Intl.NumberFormat(contentLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
    };
    const checkAnchor = () => {

        const hash = window.location.hash.substring(1);

        switch (hash) {
            case "openClaim":
                setShowClaimModal(true)
                break;
            case 'transactions':
                setActiveTabOffset(0)
                break;
            case 'claims':
                setActiveTabOffset(1)
                break;
            case 'payouts':
                setActiveTabOffset(2)
                break;
        }
    }
    const handleTabChange = (index) => setActiveTabOffset(index);
    return (
        <div className={"mt-4 mr-3 ml-3"}>
            <h1>{initStrings.online_saved}</h1>
            <div className={"divider-horizontal mb-3"}/>
            <div className="row">
                <div className={"col-lg-8 col-sm-8 d-flex justify-content-start flex-wrap"}>
                    <div className={"card card-body radius-15 border-0 w-100 in-store-saved-discount-card-body"}>
                        <div className="row">
                            <div className={"col-12 justify-content-center align-items-center"}>
                                <a>
                                    {initStrings.you_have_in_total_1}
                                    <span className="font-weight-bolder text-primary h4">
                                        €{formatNumber(sniptechBalance?.savedAmount)}
                                    </span>
                                    {initStrings.you_have_in_total_2}
                                    <span className="font-weight-bolder text-primary h4">
                                        €{formatNumber(sniptechBalance?.payableAmount)}
                                     </span>
                                    {initStrings.you_have_in_total_3}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-3 d-flex justify-content-lg-end justify-content-center align-items-center mt-sm-0 mr-1 mt-3">
                    <KnaekPrimaryButton
                        title={initStrings.payout}
                        className="mt-2"
                        onClick={checkPayout}/>
                </div>
            </div>

            <div className="col-sm-11 col-lg-11 mt-5 mb-3 d-flex justify-content-center claim-model-trigger"
                 ref={tabSectionRef}>
                <span className={"text-primary cursor-pointer"}
                      onClick={() => setShowClaimModal(true)}>{initStrings.Where_is_my_transaction}</span>
            </div>
            <div className="col-sm-11 col-lg-11 mb-3 mt-5 d-flex justify-content-center">
                <span className={"text-primary"}>{initStrings.it_may_take_48_hours_before_your_cashback_is_visible_here}</span>
            </div>

            <div className="col-sm-12 col-lg-11 mt-3 mb-3 px-0">

                {/* Tabs Navigation */}
                <div className="animated-tabs mb-5" >
                    <div className={`animated-tab ${ (activeTabOffset === 0 ? 'active' : '') }`} role="tab" onClick={() => handleTabChange(0)}>
                        {initStrings.transactions_cc}
                    </div>
                    <div className={`animated-tab ${ (activeTabOffset === 1 ? 'active' : '') }`} role="tab" onClick={() => handleTabChange(1)}>
                        {initStrings.claims}
                    </div>
                    <div className={`animated-tab ${ (activeTabOffset === 2 ? 'active' : '') }`} role="tab" onClick={() => handleTabChange(2)}>
                        {initStrings.payouts}
                    </div>
                    <div className='scrollable animated' style={{left: ( ( activeTabOffset ) * 33.3333 ) + '%'  }}></div>
                </div>

                <div className={`flex-column ${(activeTabOffset === 0 ? 'd-flex ' : 'd-none')}`}>
                    {/* Transactions Tab */}
                    <TableComponent
                        headers={[
                            { label: initStrings.converted_at, field: 'converted_at' },
                            { label: initStrings.description, field: 'description' },
                            { label: initStrings.cashback, field: 'cashback' },
                            { label: initStrings.status, field: 'statusLabel'},
                        ]}
                        rows={sniptechTransactions}
                        renderRow={(item, i) => (
                            <tr key={i}>
                                <td>{item?.converted_at}</td>
                                <td>{item?.description?.toString()}</td>
                                <td>{item.cashback}</td>
                                <td>
                                    <span className={`p-2 badge badge-${item?.statusBadge}`}>
                                        {item?.statusLabel}
                                    </span>
                                </td>
                            </tr>
                        )}
                    />
                </div>
                {/* Tickets Tab */}
                <div className={`flex-column ${(activeTabOffset === 1 ? 'd-flex ' : 'd-none')}`}>
                    <TableComponent
                        headers={[
                            { label: initStrings.name, field: 'name' },
                            { label: initStrings.type, field: 'typeLabel' },
                            { label: initStrings.creation, field: 'creationDate' },
                            { label: initStrings.status, field: 'statusLabel'},
                        ]}
                        rows={sniptechTickets}
                        renderRow={(item, i) => (
                            <tr key={i}>
                                <td>{item?.name}</td>
                                <td>{item?.typeLabel}</td>
                                <td>{item?.creationDate}</td>
                                <td>
                                    <span className={`p-2 badge badge-${item?.statusBadge}`}>
                                            {item?.statusLabel}
                                    </span>
                                </td>
                            </tr>
                        )}
                    />
                </div>
                {/* Payouts Tab */}
                <div className={`flex-column ${(activeTabOffset === 2 ? 'd-flex ' : 'd-none')}`}>
                    <TableComponent
                        headers={[
                            { label: initStrings.details, field: 'details' },
                            { label: initStrings.amount, field: 'amount' },
                            { label: initStrings.wallet, field: 'walletId' },
                            { label: initStrings.creation, field: 'creationDate'},
                            { label: initStrings.paid_at, field: 'paidDate'},
                            { label: initStrings.status, field: 'statusLabel' }
                        ]}
                        rows={sniptechPayouts}
                        renderRow={(item, i) => (
                            <tr key={i}>
                                <td>{item?.details?.toString()}</td>
                                <td>€{item?.amount}</td>
                                <td>{item?.walletId}</td>
                                <td>{item?.creationDate}</td>
                                <td>{item?.paidDate}</td>
                                <td>
                                    <span className={`p-2 badge badge-${item?.statusBadge}`}>
                                            {item?.statusLabel}
                                    </span>
                                </td>
                            </tr>
                        )}
                    />
                </div>
            </div>
            {showClaimModal && <ClaimCashbackComponent onClose={() => setShowClaimModal(false)}/>}
            {showPayoutModal && <PayoutModalComponent
                onClose={() => setShowPayoutModal(false)}
                sniptechBalance={sniptechBalance?.payableAmount}
            />}
        </div>
    );

    function checkPayout() {
        if (sniptechBalance?.payableAmount === 0) {
            failedAlertFunction(initStrings.noBalanceForPayout, '');
            return;
        }
        setShowPayoutModal(true);
    }


    async function fetchMySniptechTransactionOnline() {
        try {
            const [transactionResponse, balancesResponse, payoutsResponse, ticketsResponse] = await Promise.all([
                fetchTransactionSnip(),
                fetchSnipTechBalancesCall(),
                fetchPayoutsSnipTech(),
                fetchTicketsSnipTech(),
            ]);


            if (transactionResponse && transactionResponse.length > 0) {
                setSniptechTransactions(transactionResponse)
            }
            if (payoutsResponse && payoutsResponse.length > 0) {
                setSniptechPayouts(payoutsResponse)
            }
            if (ticketsResponse && ticketsResponse.length > 0) {
                setSniptechTickets(ticketsResponse)
            }

            setSniptechBalance(balancesResponse || 0);
        } catch (error) {
            console.error("Error fetching Sniptech data:", error);
        }
    }
}
