import React from 'react';
import ImageComponent from "./ImageComponent";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {hasAValue} from "../../uitls/SharedFunctions";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';

export default function NewLocalStoresDiscountCard({item}) {
    const citiesRS = useSelector((state) => state.citiesRS);
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    let contentLanguage = appLanguageRS === "en"? "en": "nl"
    const handleLinkClick = useHandleLinkClick();

    const {city} = useParams();

    return (
        <div className={"card radius-15 border-0 w-100 store-discount-card cursor-pointer shadow"}
             onClick={() => goToInfo()}>
            <ImageComponent src={item.company?.backgroundImage} className="homepage-online-section-online-card-background" alt={`studentenkorting-${item.company?.name}`}/>
            <div className="card-body row store-discount-card-body">
                <div className={"col-12 d-flex justify-content-center"}>
                    <div
                        className="bg-white radius-15 online-discount-card-logo shadow d-flex justify-content-center align-items-center">
                        <ImageComponent src={item.company?.logo}
                                        alt="" className={"online-discount-card-logo-img radius-15"}/>
                    </div>


                </div>
                <div className={"col-12 text-center"}>
                    <h4 className="w-100 mt-1 text-knaek-title">{item.localization[contentLanguage].subtitle}</h4>
                    <h6 className="w-100 text-knaek-regular mt-2">{item.company?.name}</h6>
                </div>
            </div>

        </div>
    );


    function hyphenateAndEncode(str) {
        // Replace spaces and forward slashes with hyphens, then URI encode the result
        return encodeURIComponent(str.replace(/\s+|\//g, '-'));
    }

    function goToInfo() {
        const targetCity = hasAValue(city)
            ? city
            : citiesRS.find(cityData => cityData.id === item.company?.cities[0])?.name;
        const targetName = item.company.name
        const targetId = item.id;

       handleLinkClick(`/studentenkorting/${hyphenateAndEncode(targetCity)}/${hyphenateAndEncode(targetName)}/${encodeURIComponent(targetId)}`);
    }

}
