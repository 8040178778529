import React, {useEffect} from "react";
import ReviewsHeader from "../../components/HeaderSection/ReviewsHeader";
import BannerReviews from "../../components/AppSections/ReviewsPageSection/BannerReviews";
import ReviewForm from "../../components/AppSections/ReviewsPageSection/ReviewForm";
import {updateLanguageRS} from "../../redux/reduxActions/reduxStoreActions";
import i18n from "i18n-js";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {appWords, WEBSITE_TYPE} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";



export default function KnaekReviews() {

    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const reviews = stringsTranslate(appWords.reviews);
    const dispatch = useDispatch();
    i18n.locale = appLanguageRS;

    useEffect(() => {
        if (!appLanguageRS) {
            if (window.location.toString().toLowerCase().includes("knaek.com")) {
                i18n.locale = "en";
                dispatch(updateLanguageRS("en"))
            } else if (window.location.toString().toLowerCase().includes("knaek.nl")) {
                i18n.locale = "nl";
                dispatch(updateLanguageRS("nl"))
            } else if (window.location.toString().toLowerCase().includes("knaek.be")) {
                i18n.locale = "be";
                dispatch(updateLanguageRS("be"))
            } else {
                i18n.locale = WEBSITE_TYPE;
                dispatch(updateLanguageRS(WEBSITE_TYPE))
            }
        } else {
            i18n.locale = appLanguageRS;
        }
    }, [appLanguageRS])


    return (
        <div className={"homepage-4 pt-5"}>
            <Helmet>
                <title>Reviews</title>
            </Helmet>
            <div className={"main"}>
                <ReviewsHeader switchLanguage={(language) => switchLanguage(language)} />
                <BannerReviews/>
                <ReviewForm/>
            </div>
        </div>
    );

    function switchLanguage(language) {
        dispatch(updateLanguageRS(language))
    }
}
