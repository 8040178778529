import React, {useEffect, useState} from 'react';
import {appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import BreadcrumbRouteNav from "../../AppCustomizedComponent/BreadcrumbRouteNav";
import {hasAValue} from "../../../uitls/SharedFunctions";
import ImageComponent from "../../AppComponents/ImageComponent";
import {useDispatch, useSelector} from "react-redux";
import {updateShowAuthModalRS} from "../../../redux/reduxActions/reduxStoreActions";
import {useLocation, useParams} from "react-router-dom";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {postRepostProblemCall} from "../../../api/ApiCalls";
import KnaekSecondaryButton from "../../AppComponents/KnaekSecondaryButton";
import {successAlertFunction} from "../../../uitls/AlertFunctions";
import CashbackOfStoreModalComponent from "../../AppComponents/CashbackOfStoreModalComponent";
import { useHandleLinkClick } from '../../../hooks/useHandleLinkClick';
import {storageKeys} from "../../../config/storageKeysConstants";



export default function SnipTechDiscountDetailFirstSectionOnlineDiscounts({snipTechOnlineDiscount, deals}) {
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const authDataRS = useSelector(state => state.authDataNEWRS);
    const location = useLocation();
    const dispatch = useDispatch()
    const {name, id} = useParams()
    const [intiVariables, setIntiVariables] = useState({})
    const [showStoreCashbackModal, setShowStoreCashbackModal] = useState(false);
    let initStrings = {
        product_category: stringsTranslate(appWords.product_category),
        cashback: stringsTranslate(appWords.cashback),
        all_webshop_discounts: stringsTranslate(appWords.all_webshop_discounts),
        go_to_webshop_incl: stringsTranslate(appWords.go_to_webshop_incl),
        login_first: stringsTranslate(appWords.login_first),
        order_your_knaek_id: stringsTranslate(appWords.order_your_knaek_id),
        to_website: stringsTranslate(appWords.to_website),
        link: stringsTranslate(appWords.link),
        login_sl: stringsTranslate(appWords.login_sl),
        get_discount: stringsTranslate(appWords.get_discount),
        oops_no_caskback_discount: stringsTranslate(appWords.oops_no_caskback_discount),
        onlineDiscountSomethingDoesNotWorkDes: stringsTranslate(appWords.onlineDiscountSomethingDoesNotWorkDes),
        reportProblem: stringsTranslate(appWords.reportProblem),
        about_cashback: stringsTranslate(appWords.about_cashback),
        all_purchases_made_on_this_site_will_be_recorded: stringsTranslate(appWords.all_purchases_made_on_this_site_will_be_recorded),
        cashback_is_never_guaranteed: stringsTranslate(appWords.cashback_is_never_guaranteed),
        you_cannot_submit_a_claim_for_online_casinos: stringsTranslate(appWords.you_cannot_submit_a_claim_for_online_casinos),
        cashback_registration_is_not_a_foolproof: stringsTranslate(appWords.cashback_registration_is_not_a_foolproof),
        purchases_will_appear: stringsTranslate(appWords.purchases_will_appear),
        cashback_is_calculated: stringsTranslate(appWords.cashback_is_calculated),
        shop_with_cashback: stringsTranslate(appWords.shop_with_cashback),
    }
    const handleLinkClick = useHandleLinkClick();

    useEffect(() => {
        let intiVariablesLocal = {}

        if (hasAValue(snipTechOnlineDiscount)) {

            let language = appLanguageRS === "nl" ? "" : "_" + appLanguageRS
            intiVariablesLocal['image'] = snipTechOnlineDiscount.background
            intiVariablesLocal['icon'] = snipTechOnlineDiscount.logo
            intiVariablesLocal['title'] = snipTechOnlineDiscount.title
            intiVariablesLocal['promotion'] = snipTechOnlineDiscount["type" + language]
            intiVariablesLocal['subtitle'] = snipTechOnlineDiscount[appLanguageRS]?.shortDes
            intiVariablesLocal['description'] = snipTechOnlineDiscount[appLanguageRS].fullDes
            intiVariablesLocal['commission_groups'] = deals

        }
        setIntiVariables(intiVariablesLocal)

    }, [snipTechOnlineDiscount, appLanguageRS, deals])


    const handleLoginClick = () => {
        // Store the current path in state
        sessionStorage.setItem(storageKeys.REDIRECT_URL, location.pathname);
        // Show the login modal
        dispatch(updateShowAuthModalRS(true));
    };
    return (
        <div className="p-0 m-0">
            {hasAValue(snipTechOnlineDiscount) && (
                <div className="container">
                    <BreadcrumbRouteNav element1={"Cashback"} element2={name}/>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <ImageComponent src={intiVariables.image} alt=""/>
                            <h4 className="mt-5 pb-3">{initStrings.about_cashback}</h4>
                            <p className="pb-3">{initStrings.all_purchases_made_on_this_site_will_be_recorded}</p>
                            <p className="pb-3">{initStrings.cashback_is_calculated}</p>
                            <p className="pb-3">{initStrings.purchases_will_appear}</p>
                            <p className="pb-3">{initStrings.cashback_registration_is_not_a_foolproof}</p>
                            <p className="pb-3">{initStrings.you_cannot_submit_a_claim_for_online_casinos}</p>
                            <p>{initStrings.cashback_is_never_guaranteed}</p>
                            <div className="col-12 cashback-info-details-size"></div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="row mr-1">
                                <div className="col-lg-2 col-md-2 col-sm-12 d-none d-md-block">
                                    <ImageComponent src={intiVariables.icon}
                                                    className="col-lg-3 col-md-12 bg-white radius-15 online-discount-details-cover-logo online-discount-details-card-logo shadow"
                                                    alt="" height={98} width={98}
                                    />
                                </div>

                                <div className="col-lg-2 col-md-2 col-sm-12 d-flex justify-content-center d-block d-md-none logo-discount-mobile">
                                    <ImageComponent src={intiVariables.icon}
                                                    className="col-lg-3 col-md-12 bg-white radius-15 online-discount-details-card-logo shadow"
                                                    alt="" height={98} width={98}
                                    />
                                </div>

                                <div className="col-lg-9 col-md-9 col-sm-12 title-position">
                                    <h4 className="mt-2 text-knaek-title ml-lg-4">{ intiVariables.title}</h4>
                                    <div className="mt-2 divider-horizontal ml-lg-4"/>
                                    <h5 className="mt-2 mb-0 pb-0 text-knaek-primary font-weight-bolder ml-lg-4">{intiVariables.subtitle}</h5>
                                    <p className="mt-0 pt-0 text-success font-size-14 font-weight-bold ml-lg-4">{intiVariables.promotion}</p>
                                </div>
                                <p className="mt-3 mb-2 ml-3 pb-3 discount-description">{intiVariables.description}</p>
                            </div>


                            {intiVariables?.commission_groups?.length > 0  && (
                                <div className="table-responsive">
                                    <table className="table table-bordered mt-2">
                                        {hasAValue(intiVariables.commission_groups) && (
                                            <>
                                                <thead>
                                                <tr>
                                                    <th className="col-7 py-3 p-2">{initStrings.product_category}</th>
                                                    <th className="col-2 py-3 text-center p-2">{initStrings.cashback}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {intiVariables.commission_groups.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className="col-7 py-3 p-2">{item[appLanguageRS]?.title}</td>
                                                        <td className="col-2 py-3 p-2 text-center">{item?.cashback?.percentage ? `${item?.cashback?.percentage}%` : `${item?.cashback?.amount} ${item?.coin}`}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </>
                                        )}
                                    </table>
                                </div>
                            )}

                            {hasAValue(authDataRS) && intiVariables?.commission_groups?.length === 0 && (
                                <div className="empty-deals-list-notification">
                                    <div className="">
                                        <h4>{initStrings.oops_no_caskback_discount}</h4>
                                        <p>{initStrings.onlineDiscountSomethingDoesNotWorkDes}</p>
                                    </div>
                                </div>
                            )}

                            {/*Buttons */}
                            {!hasAValue(authDataRS) &&
                                <div className="d-flex flex-wrap justify-content-around text-left mt-5">
                                    <div className="m-2">
                                        <KnaekSecondaryButton
                                            title={initStrings.login_first.toString().toUpperCase()}
                                            onClick={handleLoginClick}
                                        />
                                    </div>

                                </div>}

                            {hasAValue(authDataRS) && intiVariables?.commission_groups?.length > 0 &&
                                <div className="d-flex flex-wrap justify-content-around text-left mt-5">
                                    <div className="m-2">
                                        <KnaekPrimaryButton
                                            title={initStrings.shop_with_cashback.toString().toUpperCase()}
                                            onClick={() => setShowStoreCashbackModal(true)}
                                        />
                                    </div>
                                </div>}

                            {hasAValue(authDataRS) && intiVariables?.commission_groups?.length === 0 &&
                                <div className="d-flex flex-wrap justify-content-around text-left mt-5">
                                    <div className="m-2">
                                        <KnaekPrimaryButton
                                            title={initStrings.reportProblem.toString().toUpperCase()}
                                            onClick={()=> reportProblem()}
                                        />
                                    </div>
                                </div>
                        }
                        </div>
                    </div>

                    {showStoreCashbackModal && <CashbackOfStoreModalComponent snipTechOnlineDiscount={snipTechOnlineDiscount} deals={deals} onClose={() => setShowStoreCashbackModal(false)}/>}
                </div>
            )}
        </div>
    );


    async function reportProblem() {
        try {
            let data = { id: snipTechOnlineDiscount.id };
            successAlertFunction(stringsTranslate(appWords.thank_you_report_a_problem), stringsTranslate(appWords.the_problem_is_successfully_reported))
            setTimeout(() => {
                handleLinkClick('/cashback/studentenkorting')
            }, 3000);
            await postRepostProblemCall(data);
             // Adjust the delay as needed
        } catch (e) {
            console.error("An error occurred while reporting the problem:", e);
        }
    }

}
