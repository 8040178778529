import React, {useEffect, useState} from 'react';
import {hasAValue} from "../../../uitls/SharedFunctions";
import {appWords} from "../../../config/Constants";
import StoreDiscountCardSaved from "../../AppComponents/StoreDiscountCardSaved";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {useSelector} from "react-redux";


export default function SavedStoreDiscountsProfileSection({savings, storeDiscountsRS}) {
    const [totalSaved, setTotalSaved] = useState(0)

    useEffect(() => {
        if (hasAValue(savings)) {
            let total = savings.reduce((accumulator, item) => accumulator + parseFloat(item.saved), 0);
            setTotalSaved(total);
        }
    }, [savings]);

    const appLanguageRS = useSelector(state => state.appLanguageRS);

    const formatNumber = (number) => {
        let contentLanguage = appLanguageRS === "en"? "en": "nl"
        return new Intl.NumberFormat(contentLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
    };

    const initStrings = {

        header: stringsTranslate(appWords.saved_in_store),
        content: stringsTranslate(appWords.you_have_total),
        content1: stringsTranslate(appWords.received_about),
        content2: stringsTranslate(appWords.transactions),

    }


    return (
        <div className={"mt-4 ml-4"}>
            <h1>{initStrings.header}</h1>
            <div className={"divider-horizontal"}/>
            <div className={"col-lg-7 col-sm-12 col-sm-12 d-flex justify-content-start mb-5 ml-0 pl-0"}>

                <div className={"card card-body radius-15 border-0 w-100 in-store-saved-discount-card-body  mt-3"}>
                    <div className="row">

                        <div className={"col-12 justify-content-center align-items-center"}>
                            <a>
                                {initStrings.content}
                                <span className="font-weight-bolder text-primary h4">
                                    €{formatNumber(totalSaved)}
                                </span>
                                {initStrings.content1}
                                <span className="font-weight-bolder text-primary h4">
                                    {savings.length}
                                </span>
                                {initStrings.content2}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row pl-1 pr-4 pr-lg-0 pr-md-0"}>
                <div className={"col-sm-12 col-lg-8 mt-5 mb-3"}>
                    <section className={"row justify-content-start"}>
                        {savings.map((item, i) => {
                            const actionElement = storeDiscountsRS.find(element => element.id === item.id)
                            if (hasAValue(actionElement)) {
                                return (
                                        <StoreDiscountCardSaved item={actionElement} savingDetails={item} isFavorite={false}/>
                                )
                            }
                        })}
                    </section>

                </div>
            </div>
        </div>

    )
        ;
}
