import React, {useRef} from 'react';
import Partners from "./Partners";
import PartnerText from "./PartnerText";
import BecomePartnerCAT from "./BecomePartnerCAT";






export default function PartnersSection() {


    return (
        <div id="screenshots" className="section mt-5 partner-section  mb-5">
            <PartnerText/>
            {/*<Partners/>*/}
            <BecomePartnerCAT/>
        </div>
    );
}
