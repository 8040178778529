import React, {useEffect, useState} from 'react';
import {appWords} from "../../../config/Constants";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import ReviewSubmitButton from "../../AppCustomizedComponent/ReviewSubmitButton";
import StarArray from "../../AppCustomizedComponent/ReviewStarRatingIcon";
import {postUserReviewCall} from "../../../api/ApiCalls";
import { useHandleLinkClick } from '../../../hooks/useHandleLinkClick';


export default function ReviewForm() {

    const [rating, setRating] = useState(0);
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState(undefined);
    const [disableButton, setDisableButton] = useState(false);
    const handleLinkClick = useHandleLinkClick();


    const initStrings = {
        heading: stringsTranslate(appWords.trustpilot_form_heading),
        content: stringsTranslate(appWords.trustpilot_form_content),
        btnText: stringsTranslate(appWords.trustpilot_form_btn_text),
        rate_your_recent_experience: stringsTranslate(appWords.rate_your_recent_experience),
        labelText1: stringsTranslate(appWords.trustpilot_form_label_text_1),
        write_an_explanation: stringsTranslate(appWords.write_an_explanation),
        email_reviews_placeholder: stringsTranslate(appWords.email_reviews_placeholder),
        post_review: stringsTranslate(appWords.post_review)
    }

    useEffect(() => {
        const isEmailValid = email => {
            // Regular expression for email validation
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return pattern.test(email);
        };

        const isFormValid = rating && message && isEmailValid(email);
        setDisableButton(!isFormValid);
    }, [rating, message, email]);


    return (
        <section id="contact-form" className="section bg-trust-pilot h-100vh">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mt-3">
                        <form>
                            <ul className="list-group list-group-flush">
                                <div className="row justify-content-center align-content-center">
                                    <div className="col-lg-6 col-md-6 col-sm-9">
                                        <li className="col-12 list-group-item radius-15 reviews-form-fields-div border mb-3">
                                            <label>
                                                <h5>
                                                    {initStrings.rate_your_recent_experience}
                                                </h5>
                                            </label>
                                            <div className="row justify-content-start">
                                                <div className="col-12 stars-svg cursor-pointer">
                                                    <StarArray onRatingChange={setRating}/>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="col-12 list-group-item radius-15 input-reviews-form-fields-div border mb-3">
                                            <input name="email" type="email"
                                                   className="form-control p-0 justify-content-start bg-transparent shadow-none"
                                                   placeholder={initStrings.email_reviews_placeholder}
                                                   value={email}
                                                   onChange={(val) => {setEmail(val.target.value)}}
                                            />
                                        </li>
                                        <li className="col-12 list-group-item radius-15 reviews-form-text-area border mb-3">
                                            <textarea name="message"
                                                      className="form-control bg-transparent contact-form-textarea-fields shadow-none pl-0"
                                                      placeholder={initStrings.write_an_explanation}
                                                      value={message}
                                                      onChange={(val) => {
                                                          setMessage(val.target.value)
                                                      }}
                                            />
                                        </li>
                                    </div>
                                </div>
                            </ul>
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-9 col-md-6 col-lg-6">
                                    <ReviewSubmitButton title={initStrings.post_review}
                                                        onClick={() => postRating()}
                                                        disabled={disableButton}
                                                        className=" mb-5"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );

    function postRating() {
        setDisableButton(true);
        let data = {};
        data['rate'] = rating;
        data['review'] = message;
        data['email'] = email;

        postUserReviewCall(data)
            .then(result => {
                setRating(0);
                setMessage('');
                setEmail('');
                handleLinkClick('/thank-you-for-rating');
            })
            .catch(error => {
                setDisableButton(false);
                console.log("postContactMailCall error ", error);
            });
    }
};
