import React from 'react';
import {appLanguage, WEBSITE_TYPE} from "../../../config/Constants";
import KnaekNLContactSection from "./KnaekNLContactSection";
import KnaekBEContactSection from "./KnaekBEContactSection";

export default function KnaekContactSection() {
    return (
        <>
            {WEBSITE_TYPE === appLanguage.nl ? (
                <KnaekNLContactSection />
            ) : (
                <KnaekBEContactSection />
            )}
        </>
    );
}
