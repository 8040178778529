import React, {useEffect, useState, useRef, useCallback} from "react";
import KnaekPrimaryButton from "../AppComponents/KnaekPrimaryButton";
import { stringsTranslate } from "../../locales/CustomTranslater";
import { appWords } from "../../config/Constants";
import NewLocalStoresDiscountCard from "../AppComponents/NewLocalStoresDiscountCard";
import {getDiscountsCall} from "../../api/ApiCalls";

const initStrings = {
    section1heading: "Lokale kortingen",
    section1CounterText: "resultaten",
    sectionSearchHeading: "resultaten",
    sectionFilterHeading: "Studentenstad",
    searchPlaceholder: "Zoek een lokale korting",
    load_more_button: stringsTranslate(appWords.load_more_button),
};

export default function ListItemsStoreDiscounts({ filters, onTotalDiscountsUpdate }) {
    const [storeDiscounts, setStoreDiscounts] = useState([]);
    const [totalDiscountsCount, setTotalDiscountsCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [allItemsLoaded, setAllItemsLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const loadMoreButtonRef = useRef(null);
    const observer = useRef();

    const getDiscounts = useCallback(
        (currentFilters , shouldResetDiscounts) => {
            if (isLoading) return; // Keep loading condition
            setIsLoading(true);

            const params = new URLSearchParams({
                page: currentFilters.page || 1            });
            if (currentFilters.category) params.append("categoryId", currentFilters.category);
            if (currentFilters.city) params.append("cityId", currentFilters.city.id);
            if (currentFilters.searchText) params.append("query", currentFilters.searchText);

            getDiscountsCall(`?${params.toString()}`)
                .then((res) => {
                    setStoreDiscounts((prev) =>
                        shouldResetDiscounts ? res.items : [...prev, ...res.items]
                    );
                      if (onTotalDiscountsUpdate) {
                        onTotalDiscountsUpdate(res.count);
                       }
                    setTotalDiscountsCount(res.count);
                    setAllItemsLoaded(res.items.length === 0  || storeDiscounts.length + res.items.length >= res.count);
                })
                .catch((err) => {
                    console.error('API Error:', err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [isLoading] // Dependencies
    );
    useEffect(() => {
        if (isLoading || allItemsLoaded) return;

        const loadMoreOnScroll = (entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                getDiscounts({ ...filters, page: currentPage +1  }, false);
                setCurrentPage((prevPage) => prevPage + 1);
            }
        };


        observer.current = new IntersectionObserver(loadMoreOnScroll, { threshold: 1.0 });
        if (loadMoreButtonRef.current) {
            observer.current.observe(loadMoreButtonRef.current);
        }

        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [isLoading, allItemsLoaded]);

    useEffect(() => {
        setCurrentPage(1);
        setAllItemsLoaded(false);
        setStoreDiscounts([]);
        getDiscounts({ ...filters, page: 1 }, true);
    }, [filters]);
    const handleLoadMore = useCallback(() => {
        if (isLoading || allItemsLoaded) return;

        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        getDiscounts({ ...filters, page: nextPage }, false);
    }, [isLoading, allItemsLoaded, currentPage, filters]);

    return (
        <div className="col-lg-10 col-12 text-left border-top border-bottom">
            <section className="row justify-content-center pt-5">
                {storeDiscounts.map((item, i) => (
                    <div className="store-discount-card" key={i}>
                        <NewLocalStoresDiscountCard item={item} />
                    </div>
                ))}
            </section>
            {!allItemsLoaded && (
                <div className="col-12 d-flex justify-content-center align-content-center align-items-center">
                    <KnaekPrimaryButton
                        className="col-3 mt-2 mb-4"
                        ref={loadMoreButtonRef}
                        onClick={handleLoadMore}
                        title={isLoading ? "Loading..." : initStrings.load_more_button}
                    />
                </div>
            )}
        </div>
    );
}
