import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateLanguageRS} from "../../redux/reduxActions/reduxStoreActions";
import {appColors, appLanguage} from "../../config/Constants";

export default function  MobileLanguageSwitcher() {
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 1350px)").matches)
    const dispatch = useDispatch();
    const appLanguageRS = useSelector((state) => state.appLanguageRS);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 1350px)");
        const updateMatches = (e) => setMatches(e.matches);
        mediaQuery.addEventListener('change', updateMatches);

        return () => mediaQuery.removeEventListener('change', updateMatches);
    }, []);
    const handleLanguageChange = (language) => {
        dispatch(updateLanguageRS(language)); // Update Redux state
    };
    const languages = [
        { lang: appLanguage.nl, imgSrc: "https://cms.it.knaek.com/uploads/nederlands_ec831d47f1.svg", alt: "Nederlands", label: "NL" },
        { lang: appLanguage.en, imgSrc: "https://cms.it.knaek.com/uploads/english_2b352b017b.svg", alt: "English", label: "EN" },
    ];
    return matches && (
        <div  className="d-flex justify-content-center mt-2 mb-2">
            <div className="d-flex align-items-center"
                 style={{
                     border: `2px solid ${appColors.primary}`,
                     borderRadius: '10px',
                 }}
            >
                {languages.map(({ lang, imgSrc, alt, label }, index) => (
                    <div
                        key={lang}
                        onClick={() => handleLanguageChange(lang)}
                        className="d-flex align-items-center justify-content-center gap-1 py-2 px-3 cursor-pointer"
                        style={{
                            backgroundColor: appLanguageRS === lang ? appColors.primary : 'transparent',
                            transition: 'background-color 0.3s',
                            borderRadius: appLanguageRS === lang
                                ? index === 0
                                    ? '5px 0 0 5px'
                                    : index === languages.length - 1
                                        ? '0 5px 5px 0'
                                        : '5px'
                                : '5px',
                        }}
                    >
                        <div className="d-flex align-items-center"
                             style={{gap: '8px'}}
                        >
                            {/* Flag Image */}
                            <img
                                src={imgSrc}
                                alt={alt}
                                className="d-block mx-auto"
                                style={{
                                    width: '30px',
                                    height: '20px',
                                    objectFit: 'cover',
                                }}
                            />
                            {/* Language Label */}
                            <span
                                className={`d-flex align-items-center font-weight-bold`}
                                style={{ fontSize: '14px', lineHeight: '20px' , color: appLanguageRS === lang ? '#fff' : '#000'}}
                            >
                                {label}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}