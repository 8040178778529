import React, {useEffect} from 'react';
import {IoIosArrowDropdown, IoIosArrowDropup} from "react-icons/io";


export default function AccordionItemKnaekFAQ({ title, vacancyContent, isExpanded, toggleCollapse, idx, id }) {
    useEffect(() => {
        if (window.location.hash === "#" + idx) {
            toggleCollapse(idx);
        }
    }, []);

    return (
        <div key={idx} className="vacancy-card-style px-lg-2 px-md-2 px-1" id={id}>
            <div className="card-header px-lg-2 px-md-2 px-1 border-0 bg-white d-flex justify-content-between" onClick={() => toggleCollapse(idx)}>
                <section>
                    <h4 className={"d-none d-lg-block"}>{title}</h4>
                    <h6 className={"d-block d-lg-none ml-3"}>{title}</h6>
                </section>
                <section className="d-flex justify-content-center align-items-center">
                    {isExpanded === idx ? <IoIosArrowDropup className="ml-5 icon-arrow-colored" /> : <IoIosArrowDropdown className="ml-5 icon-arrow"/>}
                </section>
            </div>
            <div className={`collapse ${isExpanded === idx && "show"}`}>
                {
                    vacancyContent.map(({ header, section }, id) => (
                        <div key={`flth_${id}`}>
                            <div className="card-body">
                                <h5>{header}</h5>
                                <p dangerouslySetInnerHTML={{ __html: section }}></p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
