import React, {useRef} from 'react';
import Slider from "react-slick";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import NewLocalStoresDiscountCard from "../AppComponents/NewLocalStoresDiscountCard";


const settings = {
    speed: 1000,
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    infinite: true,
    responsive: [
        {
            breakpoint: 1140,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },

    ]

};
export default function SimilarDiscountsSliderStore({similarDiscounts}) {
    const sliderRef = useRef(null);
    return (
        <div className="container">
            <div className="col-12">
                <div className="row m-0">

                    <div className="col-12">
                        <Slider {...settings} ref={sliderRef}>
                            {/* Single Screenshot Item */}
                            {similarDiscounts.slice(0, 10).map((item, idx) => {
                                    return (
                                        <div className="col-12 w-100" key={idx}>
                                            <NewLocalStoresDiscountCard item={item}/>
                                        </div>
                                    )
                                }
                            )}
                        </Slider>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-center mr-lg-5 pr-lg-5 mt-lg-4 mb-5 ">
                    <a className={"bg-transparent slider-button-arrow  pr-5"} onClick={() => handleback()}>
                        <IoIosArrowBack className={"arrow-slider-similar-discounts"}/>
                    </a>

                    <a className={"bg-transparent slider-button-arrow  pl-lg-5"} onClick={() => handleNext()}>
                        <IoIosArrowForward className={"arrow-slider-similar-discounts"}/>
                    </a>
                </div>

            </div>
        </div>
    );

    function handleNext() {
        sliderRef.current.slickNext();
    };

    function handleback() {
        sliderRef.current.slickPrev();
    };
}
